import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { NotePopupService } from './note-popup.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AddNoteComponent } from '../add-note/add-note.component';

@Component({
    selector: 'app-note-popup',
    templateUrl: './note-popup.component.html',
    styleUrls: ['./note-popup.component.scss'],
    standalone: false
})
export class NotePopupComponent {
  protected notePopupStatus;
  @Input() buildingId?: string;

  @ViewChild('noteInput') private noteInput!: AddNoteComponent;

  constructor(
    private readonly notePopupService: NotePopupService,
    protected readonly translateService: TranslateService
  ) {
    this.notePopupStatus = notePopupService.notePopupStatus;
    notePopupService.noteStatusChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.notePopupStatus = value;
    });
    notePopupService.shown.pipe(takeUntilDestroyed()).subscribe(() => {
      this.noteInput.reset();
    });
  }

  close(): void {
    this.notePopupService.hide();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
    this.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { BuildingOverviewService } from '@services/building-overview.service';
import { SortOrder } from '@api-clients/bim';

@Component({
    selector: 'app-list-view',
    templateUrl: 'list-view.component.html',
    styleUrls: ['./list-view.component.scss'],
    standalone: false
})
export class ListViewComponent implements OnInit {
  breadcrumbTree = [
    {
      name: 'Woningportefeuille',
      link: '/',
    },
  ];

  protected defaultPageSize: number = 10;
  protected buildings: BuildingOverviewEntry[] = [];
  protected filteredBuildings: BuildingOverviewEntry[] = [];

  // column data is added in html through a loop.
  columns = [
    { key: 'buildingMetadata.name', label: 'name' },
    { key: 'buildingMetadata.address', label: 'address' },
    { key: 'buildingMetadata.postalCode', label: 'zipcode' },
    { key: 'buildingMetadata.city', label: 'city' },
    { key: 'external_id', label: 'CadastralId' },
    { key: undefined, label: '3D model' },
  ];

  protected sortBy: string = 'name';
  protected sortOrder: SortOrder = SortOrder.Asc;
  protected page: number = 1;
  protected pageSize: number = this.defaultPageSize;
  protected searchText: string = '';
  protected totalCount = 0;

  constructor(
    private readonly router: Router,
    private readonly buildingOverviewService: BuildingOverviewService
  ) {}

  async ngOnInit(): Promise<void> {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.page = 1;
    this.pageSize = this.defaultPageSize;
    this.buildingOverviewService.ownedBuildings$.subscribe((next) => {
      this.buildings = next;
      this.updateBuildings();
    });
  }

  updateBuildings(): void {
    const sortOrder = this.sortOrder === SortOrder.Asc ? 1 : -1;

    let filteredItems = this.buildings;
    const searchText = this.searchText.toLowerCase();

    if (this.searchText) {
      filteredItems = this.buildings.filter(
        (b) =>
          b.buildingMetadata.name?.toLowerCase().includes(searchText) ||
          b.buildingMetadata.city?.toLowerCase().includes(searchText) ||
          b.buildingMetadata.address?.toLowerCase().includes(searchText) ||
          b.buildingMetadata.postalCode?.toLowerCase().includes(searchText) ||
          b.external_id?.toLowerCase().includes(searchText)
      );
    }

    this.totalCount = filteredItems.length;

    if (this.sortBy.startsWith('buildingMetadata.')) {
      const key = this.sortBy.split('.')[1];
      filteredItems.sort((a, b) =>
        (a.buildingMetadata[key] ?? '').toLowerCase() <
        (b.buildingMetadata[key] ?? '').toLowerCase()
          ? 1 * sortOrder
          : -1 * sortOrder
      );
    } else {
      filteredItems.sort((a, b) =>
        (a[this.sortBy] ?? '').toLowerCase() < (b[this.sortBy] ?? '').toLowerCase()
          ? 1 * sortOrder
          : -1 * sortOrder
      );
    }

    this.filteredBuildings = filteredItems.slice(
      (this.page - 1) * this.pageSize,
      this.page * this.pageSize
    );
  }

  onPageChange(event): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.updateBuildings();
  }

  async navigateToModel(event, id: string): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['buildings/' + id + '/model/overview'], {
        queryParams: { id },
      })
    );
    window.open(url, '_blank');
  }

  toggleSort(property: string | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? 'name';
      this.sortOrder = SortOrder.Asc;
    }
    this.updateBuildings();
  }

  search(): void {
    this.updateBuildings();
  }

  protected readonly SortOrder = SortOrder;
}

<div class="dashboard-page">
  <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

  <div class="dashboard-page__header">
    <div class="dashboard-page__header__info">
      <h1>Dashboard</h1>
      <h2>Startdatum: 30-01-2025 Einddatum: 12-12-2025</h2>
    </div>

    <div class="dashboard-page__header__share">
      <button class="button-primary">Maatregelen toevoegen</button>

      <div class="share"></div>
      <div class="share"></div>
      <div class="share"></div>
      <div class="share"></div>

      <div class="add"><span class="material-symbols-rounded">add</span></div>
    </div>
  </div>

  <div
    class="dashboard-page__tiles"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="mixed">
    @for (tile of tiles; track tile) {
      <app-project-dashboard-tile [width]="tile.width ? 'full' : 'third'" [title]="tile.title">
        <ng-container *ngComponentOutlet="tile.content"></ng-container>
      </app-project-dashboard-tile>
    }
  </div>
</div>

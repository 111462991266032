import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrl: './dashboard-tile.component.scss',
  standalone: false,
})
export class ProjectDashboardTileComponent {
  @Input() width: 'third' | 'full' = 'third';
  @Input() title: string = '';

  get tileClass(): string {
    return this.width === 'full' ? 'dashboard-tile--full' : 'dashboard-tile--third';
  }
}

<div class="project-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'project-module.project-detail-page' | translate }}</h1>
    </div>
  </div>

  <form [formGroup]="formGroup" (submit)="save()" class="form">
    <div class="form-group">
      <label for="name">{{ 'name' | translate }}</label>
      <input id="name" class="input" type="text" formControlName="name" />
      <div class="error" *ngIf="formGroup.controls.name.errors?.['required']">
        {{ 'name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="description">{{ 'description' | translate }}</label>
      <input id="description" class="input" type="text" formControlName="description" />
    </div>

    <div class="form-group">
      <label for="project_number">{{ 'project_number' | translate }}</label>
      <input id="project_number" class="input" type="text" formControlName="project_number" />
    </div>

    <div class="form-group">
      <label for="subjects">{{ 'project-module.attach-buildings-and-addressable-units' | translate }}</label>
      <app-generic-subject-edit id="subjects" formControlName="subjects"></app-generic-subject-edit>
      <div class="error" *ngIf="formGroup.controls.subjects.errors?.['required']">
        {{ 'subjects-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="start_date_utc">{{ 'start_date' | translate }}</label>
      <app-date-input id="start_date_utc" formControlName="start_date_utc"></app-date-input>
    </div>

    <div class="form-group">
      <label for="end_date_utc">{{ 'end_date' | translate }}</label>
      <app-date-input id="end_date_utc" formControlName="end_date_utc"></app-date-input>
      <div class="error" *ngIf="formGroup.errors?.['dateRangeInvalid']">
        {{ 'end-date-before-begin-date-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="status">{{ 'status' | translate }}</label>
      <select formControlName="status" id="status">
        @for (status of Object.keys(ProjectStatus); track status) {
          <option [ngValue]="status">{{ 'project-status.'+status | lowercase | translate }}</option>
        }
      </select>
    </div>

    <button type="submit" class="button-primary button--has-icon" [disabled]="isSaving">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'project-module.project-save' | translate }}
    </button>
  </form>
</div>

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AuthConfigModule } from './auth/auth-config.module';
import { HomeComponent } from './views/home/home.component';
import { environment } from '@env/environment';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StyleguideComponent } from './views/styleguide/styleguide.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@shared/shared.module';
import { ListViewComponent } from './views/home/list-view/list-view.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModelViewerExternalComponent } from './views/model-viewer-external/model-viewer-external.component';
import { Configuration as UsersConfig, UserApiModule } from '@api-clients/user';
import { BimApiModule, Configuration as BimConfig } from '@api-clients/bim';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { AuthorizedComponent } from './views/callback/authorized.component';
import { Configuration as KadasterConfig, KadasterApiModule } from '@api-clients/kadaster';
import { Configuration as WorkspaceConfig, WorkspaceApiModule } from '@api-clients/workspace';
import { Configuration as s3Configuration, S3ApiModule } from '@api-clients/s3';
import { Configuration as DossiersConfig, DossierApiModule } from '@api-clients/dossier';
import { Configuration as RealEstateConfig, RealEstateApiModule } from '@api-clients/real-estate';
import { Configuration as ProductConfig, ProductApiModule } from '@api-clients/product';
import { Configuration as ProjectConfig, ProjectApiModule } from '@api-clients/project';
import { WebGLRenderer } from 'three';
import { UserService } from '@services/user.service';
import { DemoModule } from './demo-module/demo.module';
import { UsersModule } from './users-module/users.module';
import { ToastrModule } from 'ngx-toastr';
import { BuildingModule } from './building-module/building.module';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { InspectionModule } from './inspection-module/inspection.module';
import { ProjectModule } from './project-module/project.module';

const getConfig = (path: string): { basePath: string } => ({
  basePath: `${environment.API_BASE_PATH}/${path}`,
});

// const getLocalHostConfig = (): { basePath: string } => ({
//   basePath: `http://localhost:3000`,
// });

registerLocaleData(localeNl, 'Nl');
registerLocaleData(localeEn, 'En');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListViewComponent,
    StyleguideComponent,
    ModelViewerExternalComponent,
    UnauthorizedComponent,
    AuthorizedComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    BrowserAnimationsModule,
    ScrollingModule,
    AuthConfigModule,
    MatIconModule,
    SharedModule,
    DemoModule,
    UsersModule,
    BuildingModule,
    InspectionModule,
    ProjectModule,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    ToastrModule.forRoot(),
    BimApiModule.forRoot(() => new BimConfig(getConfig('bim'))),
    WorkspaceApiModule.forRoot(() => new WorkspaceConfig(getConfig('workspaces'))),
    UserApiModule.forRoot(() => new UsersConfig(getConfig('users-auth'))),
    S3ApiModule.forRoot(() => new s3Configuration(getConfig('s3'))),
    DossierApiModule.forRoot(() => new DossiersConfig(getConfig('dossiers'))),
    RealEstateApiModule.forRoot(() => new RealEstateConfig(getConfig('real-estate'))),
    ProductApiModule.forRoot(() => new ProductConfig(getConfig('products'))),
    //SolarApiModule.forRoot(() => new SolarConfig(getConfig('solarpanel'))),
    ProjectApiModule.forRoot(() => new ProjectConfig(getConfig('projects'))),
    KadasterApiModule.forRoot(
      () =>
        new KadasterConfig({ basePath: 'https://api.bag.kadaster.nl/lvbag/viewerbevragingen/v2' })
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    // App initializer is used to defer the app bootstrap until the auth service has fetched the access token.
    // Otherwise, api factories are initialized with an empty access token.
    UserService,
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService) => (): Promise<void> => userService.initialize(),
      deps: [UserService],
      multi: true,
    },
    { provide: WebGLRenderer, useFactory: (): WebGLRenderer => new WebGLRenderer(), multi: false },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { MeasureTemplateDetailComponent } from './views/measure-template-detail/measure-template-detail.component';
import { MeasureTemplateOverviewComponent } from './views/measure-template-overview/measure-template-overview.component';
import { ProjectOverviewComponent } from './views/project-overview/project-overview.component';
import { ProjectDetailComponent } from './views/project-detail/project-detail.component';
import { MeasureOverviewComponent } from './views/measure-overview/measure-overview.component';
import { MeasureDetailComponent } from './views/measure-detail/measure-detail.component';
import { ProjectDashboardComponent } from './views/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'measure-template',
    component: MeasureTemplateOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'measure-template/new',
    component: MeasureTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'measure-template/:id',
    component: MeasureTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'measure',
    component: MeasureOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'measure/new',
    component: MeasureDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'measure/:id',
    component: MeasureDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'project',
    component: ProjectOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'project/dashboard',
    component: ProjectDashboardComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'project/new',
    component: ProjectDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'project/:id',
    component: ProjectDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import {
  InspectionFinding,
  InspectionTemplateQuestion,
  InspectionValueValueText,
} from '@api-clients/dossier';

@Component({
    selector: 'app-inspection-open-question',
    templateUrl: './open-question.component.html',
    styleUrl: './open-question.component.scss',
    standalone: false
})
export class OpenQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion | InspectionFinding;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
    ];
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  isFinding(): boolean {
    return 'value' in this.question;
  }

  get value(): string | boolean {
    const finding = this.question as InspectionFinding;
    if (finding.value === null || finding.value === undefined) {
      return '';
    }

    return (finding.value as InspectionValueValueText).text;
  }

  set value(value: string) {
    const finding = this.question as InspectionFinding;
    finding.value = { text: value, type: 'Text' };
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelViewerComponent } from '../model-viewer/model-viewer.component';

@Component({
    selector: 'app-bim-detail',
    templateUrl: './bim-detail.component.html',
    styleUrl: './bim-detail.component.scss',
    standalone: false
})
export class BimDetailComponent {
  protected bimId?: string;
  protected modelActive: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.bimId = params['bim_id'];
    });
  }

  toggleViewMode(): void {
    if (this.modelActive) {
      void this.router.navigate(['bim', this.bimId, 'floor-plan', 'overview']);
    } else {
      void this.router.navigate(['bim', this.bimId, 'model', 'overview']);
    }
  }

  onRouterOutletActivate(e: unknown): void {
    this.modelActive = e instanceof ModelViewerComponent;
  }
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { BuildingOverviewService } from '@services/building-overview.service';
import { Map3DLayerComponent } from '@shared/components/viewer/map-3d/map-3d-layer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from '@shared/services/toastr.service';
import { LngLat } from 'maplibre-gl';
import { AmbiguousBuildingId } from '@shared/components/viewer/map-3d/utils/three_utils';

export type LatLngZoom = { lat: number; lng: number; zoom: number };

const initialPose: LatLngZoom = { lat: 52.242789, lng: 6.849455, zoom: 16.5 }; // UT

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  standalone: false,
})
export class ViewerComponent {
  private currentPose: BehaviorSubject<LatLngZoom>;
  public currentPose$: Observable<LatLngZoom>;
  protected selectedBuilding = new BehaviorSubject<BuildingOverviewEntry | undefined>(undefined);
  public selectedBuilding$: Observable<BuildingOverviewEntry | undefined> =
    this.selectedBuilding.asObservable();

  @Output() requestAddBuildingToOwnedBuildings = new EventEmitter<BuildingOverviewEntry>();
  @ViewChild('map3dLayer')
  protected map3dLayer!: Map3DLayerComponent;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    protected readonly buildingOverviewService: BuildingOverviewService,
    private readonly toastrService: ToastrService
  ) {
    const { lat, lng, zoom, cadastralId } = this.activatedRoute.snapshot.queryParams;
    this.currentPose = new BehaviorSubject({
      lat: lat || initialPose.lat,
      lng: lng || initialPose.lng,
      zoom: zoom || initialPose.zoom,
    });

    const pose: string | null = localStorage.getItem('pose');
    if (pose) {
      this.changeLatLngZoom(JSON.parse(pose));
    } else if (lat === undefined || lng === undefined || zoom === undefined) {
      // Set the initial pose to cover all owned buildings
      this.buildingOverviewService.ownedBuildings$.pipe(first()).subscribe((buildings) => {
        const latLngZoom = cameraPoseToCoverAllBuildings(buildings);
        this.changeLatLngZoom(latLngZoom);
      });
    }

    this.currentPose$ = this.currentPose.asObservable();

    if (cadastralId) {
      this.buildingOverviewService
        .getLatLonFromCadastralId(cadastralId)
        .subscribe(({ lat, lng }) => {
          this.currentPose.next({ lat, lng, zoom });
        });
      this.selectPand({ cadastralId });
    }

    this.buildingOverviewService.ownedBuildings$
      .pipe(takeUntilDestroyed())
      .subscribe((buildings) => {
        // Reselect the building if it is still in the list
        const selectedBuilding = buildings.find(
          (building) =>
            (building.real_estate_id &&
              building.real_estate_id === this.selectedBuilding.value?.real_estate_id) ||
            (building.external_id &&
              building.external_id === this.selectedBuilding.value?.external_id)
        );
        if (selectedBuilding) {
          this.selectPand({ realEstateId: selectedBuilding.real_estate_id! });
        }
      });
  }

  async addLatLngToOwnedBuildings(location: LngLat): Promise<void> {
    const building = { buildingMetadata: { location } } as BuildingOverviewEntry;
    const newBuilding =
      await this.buildingOverviewService.requestAddBuildingToOwnedBuildings(building);
    this.selectPand({ realEstateId: newBuilding.real_estate_id! });
    this.toastrService.showSuccess('add-building-save-success', 'add-building-save-title');
  }

  async addSelectedBuildingToOwnedBuildings(): Promise<void> {
    await this.buildingOverviewService.requestAddBuildingToOwnedBuildings(
      this.selectedBuilding.value!
    );
    this.toastrService.showSuccess('add-building-save-success', 'add-building-save-title');
  }

  selectAndGoToPand(cadastralId: string): void {
    this.selectPand({ cadastralId });
    this.buildingOverviewService.getLatLonFromCadastralId(cadastralId).subscribe(({ lng, lat }) => {
      this.changeLatLngZoom({ lat, lng, zoom: 18 });
    });
  }

  selectPand(ambiguousId: AmbiguousBuildingId | undefined): void {
    if (!ambiguousId) {
      // Deselected building
      this.setPathCadastralId(null);
      this.selectedBuilding.next(undefined);
    } else if ('cadastralId' in ambiguousId) {
      // Selected building with cad id
      this.buildingOverviewService
        .getBuildingByCadId(ambiguousId.cadastralId)
        .subscribe((building) => {
          this.setPathCadastralId(ambiguousId.cadastralId);
          this.selectedBuilding.next(building);
        });
    } else if ('realEstateId' in ambiguousId) {
      // Selected building with real estate id
      this.buildingOverviewService
        .getBuildingByRealEstateId(ambiguousId.realEstateId)
        .subscribe((building) => {
          this.setPathCadastralId(building?.external_id || null);
          this.selectedBuilding.next(building);
        });
    }
  }

  setPathCadastralId(cadastralId: string | null): void {
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { cadastralId },
      queryParamsHandling: 'merge',
    });
  }

  changeLatLngZoom(latLngZoom: LatLngZoom): void {
    const { lat, lng, zoom } = latLngZoom;
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        lat: lat.toFixed(6),
        lng: lng.toFixed(6),
        zoom: zoom.toFixed(1),
      },
      queryParamsHandling: 'merge',
    });
    localStorage.setItem('pose', JSON.stringify(latLngZoom));
    this.currentPose.next(latLngZoom);
  }
}

function cameraPoseToCoverAllBuildings(buildings: BuildingOverviewEntry[]): LatLngZoom {
  const locations = buildings.flatMap((b) => b.buildingMetadata.location);
  const min = locations.reduce((acc, cur) => {
    return new LngLat(Math.min(acc.lng, cur.lng), Math.min(acc.lat, cur.lat));
  });
  const max = locations.reduce((acc, cur) => {
    return new LngLat(Math.max(acc.lng, cur.lng), Math.max(acc.lat, cur.lat));
  });

  const TILE_SIZE = 512;
  const SIDEBAR_WIDTH = 200;
  const HEIGHT = window.innerHeight;
  const WIDTH = window.innerWidth;
  // Are we using width or height to set the zoom level?
  const widthIsTightest = (max.lng - min.lng) / WIDTH > (max.lat - min.lat) / HEIGHT;
  let zoom: number;
  if (widthIsTightest) {
    const widthGeoCoverage = max.lng - min.lng + 0.01;
    zoom = Math.log2(((WIDTH - SIDEBAR_WIDTH) * 360) / widthGeoCoverage / TILE_SIZE);
  } else {
    const heightGeoCoverage = max.lat - min.lat + 0.01;
    zoom = Math.log2((180 * HEIGHT * 180) / heightGeoCoverage / TILE_SIZE);
  }
  return {
    lat: (min.lat + max.lat) / 2,
    lng: (min.lng + max.lng) / 2,
    zoom,
  };
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-information-description',
    templateUrl: './information-description.component.html',
    styleUrls: ['./information-description.component.scss'],
    standalone: false
})
export class InformationDescriptionComponent {
  @Input() title = '';
}

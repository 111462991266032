import { Component } from '@angular/core';
import { BimPropertyService, TimelineService } from '../../services';
import { DossierDetailService } from '../../services/dossier-detail.service';

@Component({
    selector: 'app-building-wrapper',
    templateUrl: './building-wrapper.component.html',
    styleUrl: './building-wrapper.component.scss',
    providers: [TimelineService, BimPropertyService, DossierDetailService],
    standalone: false
})
export class BuildingWrapperComponent {
  constructor() {}
}

/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MeasureSubjectRealEstateBuildingReference { 
    real_estate_building_id: string;
    type: MeasureSubjectRealEstateBuildingReference.TypeEnum;
}
export namespace MeasureSubjectRealEstateBuildingReference {
    export type TypeEnum = 'RealEstateBuilding' | 'Bim' | 'BimElement';
    export const TypeEnum = {
        RealEstateBuilding: 'RealEstateBuilding' as TypeEnum,
        Bim: 'Bim' as TypeEnum,
        BimElement: 'BimElement' as TypeEnum
    };
}



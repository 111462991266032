import { Component, HostListener, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MeasurePopupService } from './measure-copy-popup.service';
import {
  MeasureSortColumn,
  MeasuresService,
  MeasureTemplateListItem,
  MeasureTemplatesService,
  SortOrder,
} from '@api-clients/project';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  debounceTime,
  distinctUntilChanged,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

export interface MeasureStatus {
  active: boolean;
}

@Component({
  selector: 'app-measure-copy-popup',
  templateUrl: './measure-copy-popup.component.html',
  styleUrl: './measure-copy-popup.component.scss',
  standalone: false,
})
export class MeasureCopyPopupComponent implements OnInit, AfterViewInit {
  protected measureStatus: MeasureStatus;
  protected selectedTemplate?: MeasureTemplateListItem;

  protected searchControl = new FormControl();
  protected templates: MeasureTemplateListItem[] = [];
  protected pageIndex = 0;
  protected pageSize = 10;
  protected totalItems = 0;
  protected isLoading = false;

  @ViewChild(MatAutocompleteTrigger, { static: false })
  autocompleteTrigger!: MatAutocompleteTrigger;

  @ViewChild('auto', { static: false }) autocompletePanel!: MatAutocomplete;

  constructor(
    private readonly measurePopupService: MeasurePopupService,
    private readonly measureTemplatesService: MeasureTemplatesService,
    private readonly router: Router,
    private readonly measuresService: MeasuresService
  ) {
    this.measureStatus = measurePopupService.measureStatus;
    measurePopupService.measureStatusChange.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.measureStatus = value;
    });
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.pageIndex = 0;
          this.templates = [];
        }),
        switchMap((value) => this.loadTemplates(value))
      )
      .subscribe();
  }

  loadTemplates(filterText: string = ''): Observable<MeasureTemplateListItem[]> {
    if (this.isLoading) return of([]);

    this.isLoading = true;
    return this.measureTemplatesService
      .templatesGet(
        this.pageIndex,
        this.pageSize,
        MeasureSortColumn.Name,
        SortOrder.Asc,
        filterText
      )
      .pipe(
        tap((response) => {
          this.totalItems = response.count;
          if (this.pageIndex === 0) {
            this.templates = response.items;
          } else {
            this.templates = [...this.templates, ...response.items];
          }
          this.isLoading = false;
        }),
        map((response) => response.items)
      );
  }

  ngAfterViewInit(): void {
    this.autocompletePanel.opened.subscribe(() => {
      setTimeout(() => this.observeScroll(), 100); // Ensure panel is rendered before observing
    });
  }

  observeScroll(): void {
    if (!this.autocompletePanel.panel) return;

    const panel = this.autocompletePanel.panel.nativeElement;

    const scroll = (): void => {
      const atBottom = panel.scrollTop + panel.clientHeight >= panel.scrollHeight - 10; // Buffer

      if (atBottom && !this.isLoading && this.templates.length < this.totalItems) {
        this.pageIndex++;
        this.loadTemplates(this.searchControl.value).subscribe();
      }
    };
    panel.addEventListener('scroll', scroll);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTemplate = event.option.value;
  }

  navigateToNewMeasure(): void {
    this.close();
    void this.router.navigate(['/measure', 'new']);
  }

  close(): void {
    this.measurePopupService.hide();
  }

  async saveAndFinish(): Promise<void> {
    if (this.selectedTemplate === undefined) return;

    const measureTemplate = await lastValueFrom(
      this.measureTemplatesService.templatesMeasureTemplateIdGet(this.selectedTemplate.id)
    );

    this.measuresService
      .measuresPost({
        name: measureTemplate.name,
        description: measureTemplate.description,
        calculations: measureTemplate.calculations,
        subjects: [],
        variables: measureTemplate.variables,
      })
      .subscribe((next) => {
        void this.router.navigate(['/measure', next.id]);
      });
    this.close();
  }

  displayFn(item: MeasureTemplateListItem): string {
    return item && item.name;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
    this.close();
  }
}

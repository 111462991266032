import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filesize',
    standalone: false
})
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    if (size === 0) {
      return '0kB';
    }
    if (size < 1024) {
      return size + 'B';
    }
    if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + 'kB';
    }
    if (size < 1024 * 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + 'MB';
    }
    return (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
  }
}

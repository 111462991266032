import { Component, OnInit } from '@angular/core';
import {
  DossierDocument,
  DossierFile,
  DossierImage,
  Inspection,
  InspectionSection,
  InspectionsService,
  InspectionTemplateSection,
  TimeLineType,
} from '@api-clients/dossier';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from '@shared/services/toastr.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { InspectionService } from '../../services/inspection.service';
import { BuildingsService } from '@api-clients/real-estate';
import { isRealEstateBuildingIdGenericSubject } from '../../../building-module/utils';
import { DossierService } from '@services/dossier.service';

@Component({
  selector: 'app-inspection-detail',
  templateUrl: './inspection-detail.component.html',
  styleUrl: './inspection-detail.component.scss',
  standalone: false,
})
export class InspectionDetailComponent implements OnInit {
  protected readonly TimeLineType = TimeLineType;
  private inspectionId: string;
  protected inspection?: Inspection;
  protected edit = false;
  protected isSaving: boolean = false;
  protected currentSection: InspectionTemplateSection | InspectionSection | undefined;
  protected address: string = '';
  protected selectedFile: DossierImage | DossierFile | DossierDocument | undefined;
  protected selectedFileUrl: string | undefined;

  protected breadcrumbTree = [
    {
      translate: 'inspections-overview-page',
      link: '/inspections',
    },
    {
      translate: 'edit',
      link: '/inspections/{id}',
    },
  ];

  constructor(
    private readonly inspectionsService: InspectionsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    protected readonly inspectionService: InspectionService,
    private readonly buildingsService: BuildingsService,
    protected readonly dossierService: DossierService
  ) {
    this.inspectionId = this.route.snapshot.params['id'];
    this.route.queryParams.subscribe((params) => {
      this.edit = params['edit'] === 'true';
    });
  }

  ngOnInit(): void {
    this.inspectionsService.inspectionIdGet(this.inspectionId).subscribe((inspection) => {
      this.inspection = inspection;
      this.inspectionService.setInspectionOrTemplate(inspection);

      if (this.inspection?.content.sections) {
        this.inspectionService.setSection(this.inspection.content.sections[0]);
      }

      if (
        !this.inspection ||
        !this.inspection.subject ||
        !isRealEstateBuildingIdGenericSubject(this.inspection.subject)
      )
        return;

      this.buildingsService
        .includingAddressesIdGet(this.inspection?.subject.real_estate_building_id)
        .subscribe((building) => {
          this.address =
            building.addresses.length > 0
              ? `${building.addresses[0].address} ${building.addresses[0].house_number} <br/> ${building.addresses[0].zip_code} ${building.addresses[0].place}`
              : '';
        });
    });
  }

  async openImage(image_id: string): Promise<void> {
    this.selectedFileUrl = await this.dossierService.getImagePresignedUrl(image_id);
    this.selectedFile = await this.dossierService.getImage(image_id);
  }

  changeSwitch(value: boolean): void {
    this.edit = !value;
    //add edit value to query params

    // Update the query parameters
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { edit: this.edit },
      queryParamsHandling: 'merge', // Merge with existing query parameters
    });
  }

  save(): void {
    if (this.isSaving || !this.inspection) return;
    this.isSaving = true;

    // changed inspection
    this.inspectionsService.inspectionPut(this.inspection).subscribe((next) => {
      this.inspection = next;
      this.isSaving = false;
      this.edit = false;
      this.toastrService.showSuccess(
        'dossier-module.inspection.save-success',
        'dossier-module.inspection.save-title'
      );
    });

    this.edit = false;
  }

  saveAndFinish(): void {
    if (this.isSaving || !this.inspection) return;
    this.isSaving = true;

    // changed inspection
    this.inspectionsService.inspectionFinishPut(this.inspection).subscribe((next) => {
      this.inspection = next;
      this.inspectionService.setInspectionOrTemplate(next);
      this.isSaving = false;
      this.edit = false;
      this.toastrService.showSuccess(
        'dossier-module.inspection.save-success',
        'dossier-module.inspection.save-title'
      );
    });

    this.edit = false;
  }

  itemDropped(event: CdkDragDrop<InspectionTemplateSection>): void {
    if (event.previousIndex === event.currentIndex) return;
    this.arrayMove(this.inspection?.content.sections, event.previousIndex, event.currentIndex);
  }

  arrayMove(arr, fromIndex: number, toIndex: number): void {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  activateSection(section: InspectionSection): void {
    this.currentSection = section;
    this.inspectionService.setSection(section);
  }

  deleteSection(section: InspectionSection): void {
    this.inspectionService.deleteSection(section);
  }
}

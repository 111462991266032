import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Pipe({
    name: 'secure',
    standalone: false
})
export class SecurePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  transform(url: string): Observable<string | ArrayBuffer | null> {
    return new Observable<string | ArrayBuffer | null>((observer) => {
      // This is a tiny blank image
      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

      this.http.get(url, { responseType: 'blob' }).subscribe((response): void => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = function (): void {
          observer.next(reader.result);
        };
      });

      return { unsubscribe(): void {} };
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { OrganizationsService, UserDto, UsersService } from '@api-clients/user';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { PermissionService } from '../../../auth/permission-service';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';

@Component({
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss'],
  standalone: false,
})
export class UsersPageComponent implements OnInit {
  //Todo: eventually this needs to be done at the backend so getting only the filtered users instead of getting all users and filtering them here
  public users: UserDto[] = [];
  public filteredUsers: UserDto[] = [];
  defaultPageSize: number = 10;
  sortBy: string = '';
  sortOrder: number = 1; // 1 = ascending, -1 = descending
  searchText: string = '';
  selectedUser: string = '';

  breadcrumbTree = [
    {
      translate: 'users-overview-page',
      link: '/users',
    },
  ];

  // column data is added in html through a loop.
  columns = [
    { key: 'firstName', label: 'first-name' },
    { key: 'lastName', label: 'last-name' },
    { key: 'email', label: 'email' },
    { key: 'jobTitle', label: 'role' },
    { key: 'companyId', label: 'Company' },
    { key: '', label: 'remove' },
  ];

  constructor(
    private router: Router,
    private userService: UserService,
    private usersApiService: UsersService,
    private organizationService: OrganizationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.permissionService.userHasPermissionWithScope(Resource.AllUsers, Scope.READ)) {
      this.users = await lastValueFrom(this.usersApiService.getAllUsers());
    } else {
      const organizationId = this.userService.organizationId;
      if (!organizationId) return console.warn('No organization id found');
      this.users = await lastValueFrom(this.usersApiService.getOrganizationUsers(organizationId));
    }

    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.updateUsers(1, this.defaultPageSize);
  }

  createNewUser(): void {
    void this.router.navigate(['users', 'new']);
  }

  updateUsers(page: number, size: number, users = this.users): void {
    this.filteredUsers = users.slice((page - 1) * size, page * size);
  }

  onPageChange(event: { page: number; pageSize: number }): void {
    this.updateUsers(event.page, event.pageSize);
  }

  getRoleNames(roles?: string | null): string {
    //TODO FIX At this point we only get job title instead of actual roles
    if (!roles) return '';
    return roles;
  }

  async getCompanyName(companyId: string): Promise<string> {
    if (!companyId) return '';
    return (await lastValueFrom(this.organizationService.getOrganization(companyId))).name!;
  }

  goToUserDetail(user: UserDto): void {
    void this.router.navigate(['users', user.id]);
  }

  userToRemovePopup(event: MouseEvent, userId?: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (!userId) return;
    this.selectedUser = userId;
    this.confirmationDialogService.open();
  }

  removeUser(): void {
    this.usersApiService.deleteUser(this.selectedUser).subscribe(() => {
      this.users = this.users.filter((user) => user.id !== this.selectedUser);
      this.updateUsers(1, this.defaultPageSize);
    });
  }

  isValidLoginDate(date?: string | null): boolean {
    //TO FIX All returning dates are in the year 1 right now...
    return !!date && new Date(date).getFullYear() > 1970;
  }

  toggleSort(property: string): void {
    if (this.sortBy === property) {
      this.sortOrder = -this.sortOrder;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property;
      this.sortOrder = 1;
    }

    this.users = this.users.slice().sort((a, b) => {
      // the OR case is to catch empty cells
      const nameA = (a?.[property] || '').toLocaleLowerCase();
      const nameB = (b?.[property] || '').toLocaleLowerCase();
      return this.sortOrder === 1 ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    this.search();
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.filteredUsers = this.users.filter((user) => {
        return (
          user.firstName?.toLocaleLowerCase().match(searchValue) ||
          user.lastName?.toLocaleLowerCase().match(searchValue) ||
          user.email?.toLocaleLowerCase().match(searchValue) ||
          user.jobTitle?.toLocaleLowerCase().match(searchValue)
        );
      });
    } else {
      this.updateUsers(1, JSON.parse(localStorage.getItem('pageSize') || 'null') || 10);
    }
    this.updateUsers(
      1,
      JSON.parse(localStorage.getItem('pageSize') || 'null') || 10,
      this.filteredUsers
    );
  }

  protected readonly Resource = Resource;
  protected readonly Scope = Scope;
}

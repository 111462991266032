import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AddressableUnitReference,
  GenericSubject,
  GenericSubjectAddressableUnitReference,
  GenericSubjectRealEstateBuildingReference,
  RealEstateBuildingReference,
} from '@api-clients/project';
import { BuildingOverviewService } from '@services/building-overview.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AddressableUnitDto, AddressableUnitsService } from '@api-clients/real-estate';

@Component({
  selector: 'app-generic-subject-edit',
  standalone: false,
  templateUrl: './generic-subject-edit.component.html',
  styleUrl: './generic-subject-edit.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenericSubjectEditComponent),
      multi: true,
    },
  ],
})
export class GenericSubjectEditComponent implements ControlValueAccessor {
  @Input({ required: true }) formControlName!: string;
  @Input({ required: true }) id!: string;
  @Input() type = 'text'; // Default type is text
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  protected value: GenericSubject[] = [];
  protected filteredBuildings$: BehaviorSubject<(BuildingOverviewEntry | AddressableUnitDto)[]> =
    new BehaviorSubject<(BuildingOverviewEntry | AddressableUnitDto)[]>([]);

  protected readonly formControl = new FormControl([this.formControlName]);

  protected buildings: Map<string, BuildingOverviewEntry> = new Map();
  protected addressableUnits = new Map<string, AddressableUnitDto>();

  constructor(
    private readonly buildingOverviewService: BuildingOverviewService,
    private readonly addressableUnitsService: AddressableUnitsService
  ) {
    this.buildingOverviewService.ownedBuildings$.subscribe((ownedBuildings) => {
      this.buildings = new Map(
        ownedBuildings.map((building) => [building.real_estate_id!, building])
      );
    });
  }

  add(entry: BuildingOverviewEntry | AddressableUnitDto): void {
    if (this.isBuilding(entry)) {
      const real_estate_id = entry.real_estate_id;
      if (
        this.value.find(
          (v) =>
            this.isRealEstateBuildingReference(v) && v.real_estate_building_id === real_estate_id
        )
      )
        return;
      this.value.push(<GenericSubjectRealEstateBuildingReference>{
        real_estate_building_id: real_estate_id,
        type: 'RealEstateBuilding',
      });
    } else {
      const addressable_unit_id = entry.id;
      if (
        this.value.find(
          (v) => this.isAddressableUnitReference(v) && v.addressable_unit_id === addressable_unit_id
        )
      )
        return;
      this.value.push(<GenericSubjectAddressableUnitReference>{
        addressable_unit_id: addressable_unit_id,
        type: 'AddressableUnit',
      });

      // also add the addressable unit to the addressableUnits map
      this.addressableUnits.set(entry.id, entry);
    }
    this.onChange(JSON.stringify(this.value));
    this.input.nativeElement.value = '';
  }

  // Function that will be called when value changes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange = (value: any): void => {
    this.value = JSON.parse(value);
  };

  private onTouched = (): void => {};

  // Called when Angular wants to update the value
  async writeValue(value: string): Promise<void> {
    if (value) {
      this.value = JSON.parse(value);

      const addressable_unit_references = this.value
        .filter((s) => this.isAddressableUnitReference(s))
        .map((s) => s.addressable_unit_id);
      const addressable_units = await Promise.all(
        addressable_unit_references.map(async (item) => {
          return lastValueFrom(this.addressableUnitsService.unitsIdGet(item));
        })
      );

      addressable_units.forEach((addressable_unit) => {
        this.addressableUnits.set(addressable_unit.id, addressable_unit);
      });
    }
  }

  // Called when form control is changed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Called when form control is touched
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Called when component should be enabled/disabled
  setDisabledState?(isDisabled: boolean): void {
    console.warn('setDisabledState not implemented', isDisabled);
    // Handle disabling logic if necessary
  }

  remove(subject: GenericSubject): void {
    this.value.splice(this.value.indexOf(subject), 1);
    this.onChange(JSON.stringify(this.value));
  }

  filterBuildings(input: string): BuildingOverviewEntry[] {
    if (!input) return Array.from(this.buildings.values());
    const lowercaseInput = input.toLowerCase();

    return Array.from(this.buildings.values()).filter(
      (b) =>
        `${b.buildingMetadata.city} - ${b.buildingMetadata.address}`
          .toLowerCase()
          .includes(lowercaseInput) ||
        b.buildingMetadata.addresses.some((addressable_unit) =>
          `${addressable_unit.place} - ${addressable_unit.address} ${addressable_unit.house_number}`
            .toLowerCase()
            .includes(lowercaseInput)
        )
    );
  }

  inputChange(evt: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterText = (evt.target as any).value;
    const filteredBuildings = this.filterBuildings(filterText);
    this.filteredBuildings$.next(filteredBuildings);
  }

  // Type Guard function to check if it's a BuildingOverviewEntry
  isBuilding(entry: BuildingOverviewEntry | AddressableUnitDto): entry is BuildingOverviewEntry {
    return (entry as BuildingOverviewEntry).real_estate_id !== undefined;
  }

  // Type Guard function to check if it's an AddressableUnitDto
  isAddressableUnit(
    entry: BuildingOverviewEntry | AddressableUnitDto
  ): entry is AddressableUnitDto {
    return (entry as AddressableUnitDto).id !== undefined;
  }

  isRealEstateBuildingReference(
    entry: RealEstateBuildingReference | AddressableUnitReference
  ): entry is RealEstateBuildingReference {
    return (entry as RealEstateBuildingReference).real_estate_building_id !== undefined;
  }

  isAddressableUnitReference(
    entry: RealEstateBuildingReference | AddressableUnitReference
  ): entry is AddressableUnitReference {
    return (entry as AddressableUnitReference).addressable_unit_id !== undefined;
  }
}

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { AdditionalPropertiesPipe } from './additional-properties-pipe';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { Resource } from '../../../../../resources';
import { Scope } from '../../../../../scopes';

@UntilDestroy()
@Component({
    selector: 'app-building-info',
    templateUrl: './building-info.component.html',
    styleUrls: ['./building-info.component.scss'],
    providers: [AdditionalPropertiesPipe],
    standalone: false
})
export class BuildingInfoComponent implements OnInit {
  @Input({ required: true }) public newSelectedBuilding!: Observable<
    BuildingOverviewEntry | undefined
  >;

  @Output() public requestAddSelectedBuildingToOwnedBuildings = new EventEmitter();
  @Output() public requestCloseSidebar = new EventEmitter();

  protected selectedBuilding: BuildingOverviewEntry | undefined = undefined;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.newSelectedBuilding.subscribe((building) => {
      this.selectedBuilding = building;
    });
  }

  addBuilding(): void {
    this.requestAddSelectedBuildingToOwnedBuildings.emit();
  }

  openDossier(): void {
    if (this.selectedBuilding!.real_estate_id) {
      void this.router.navigate([
        '/buildings/' + this.selectedBuilding!.real_estate_id + '/dossier',
      ]);
    }
  }

  closeSidebar(): void {
    this.requestCloseSidebar.emit();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
    this.closeSidebar();
  }

  protected readonly Resource = Resource;
  protected readonly Scope = Scope;
}

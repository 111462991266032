import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  get listView(): boolean {
    return this.route.snapshot.queryParamMap.get('list') === 'true';
  }

  changeView(): void {
    void this.router.navigate([], {
      queryParams: { list: !this.listView },
      queryParamsHandling: 'merge',
    });
  }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-information-link',
    templateUrl: './information-link.component.html',
    styleUrls: ['./information-link.component.scss'],
    standalone: false
})
export class InformationLinkComponent {
  @Input() title = '';
  @Input() link = '';
  @Input() icon = '';
  @Input() iconStyle = 'material-symbols-rounded';
}

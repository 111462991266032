<div class="organization-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>
      <h1 class="h4">{{ 'organization-detail-page' | translate }}</h1>
    </div>
  </div>

  <form class="form" [formGroup]="organizationDetailForm" (submit)="updateOrganization()">
    <div class="form-group">
      <label for="logo">{{ 'company-picture' | translate }}</label>
      <app-profile-picture [isRectangle]="true"></app-profile-picture>
    </div>

    <div class="form-group color-picker-group">
      <span class="label">{{ 'theme-colors' | translate }}</span>
      <app-color-picker
        [color]="organizationDetailForm.controls.primaryThemeColorHex.value"
        (colorChange)="checkPrimaryColor($event)"
        [description]="'primary-color' | translate"></app-color-picker>
      <app-color-picker
        [color]="organizationDetailForm.controls.secondaryThemeColorHex.value"
        (colorChange)="checkSecondaryColor($event)"
        [description]="'secondary-color' | translate"></app-color-picker>
    </div>

    <div class="form-group">
      <label for="name">{{ 'name' | translate }}</label>
      <input id="name" name="name" type="text" formControlName="name" />
      <div class="error" *ngIf="organizationDetailForm.controls.name.hasError('required')">
        {{ 'name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="phoneNumber">{{ 'telephone-number' | translate }}</label>
      <input id="phoneNumber" name="phoneNumber" type="text" formControlName="phoneNumber" />
      <div class="error" *ngIf="organizationDetailForm.controls.phoneNumber?.hasError('pattern')">
        {{ 'phone-invalid-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="email">{{ 'email-address' | translate }}</label>
      <input id="email" name="email" type="text" formControlName="emailAddress" />
      <div class="error" *ngIf="organizationDetailForm.controls.emailAddress?.hasError('required')">
        {{ 'email-required-error' | translate }}
      </div>
      <div class="error" *ngIf="organizationDetailForm.controls.emailAddress?.hasError('email')">
        {{ 'email-invalid-error' | translate }}
      </div>
    </div>

    <div formGroupName="address" class="subform">
      <app-address-detail></app-address-detail>
    </div>
    <button
      type="submit"
      class="button-primary button--has-icon"
      [disabled]="isSaving"
      *ngIf="canUpdate">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'organization-save' | translate }}
    </button>

    <button
      type="button"
      *ngIf="canDelete && !isNew"
      (click)="organizationToRemovePopup()"
      class="button-remove">
      {{ 'organization-remove' | translate }}
    </button>
  </form>
</div>

<app-confirmation-dialog
  [title]="'organization-delete-title' | translate"
  [description]="'organization-delete-description' | translate"
  [icon]="'bin'"
  [remove]="true"
  [confirmText]="'organization-delete-confirm' | translate"
  (confirmOutput)="removeOrganization()"></app-confirmation-dialog>

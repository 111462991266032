import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Inspection,
  InspectionTemplatePropertyType,
  InspectionTemplateQuestion,
} from '@api-clients/dossier';
import { QuestionType } from '@api-clients/workspace';

@Component({
  selector: 'app-inspection-section-item',
  templateUrl: './section-item.component.html',
  styleUrl: './section-item.component.scss',
  standalone: false,
})
export class SectionItemComponent {
  @Input() item!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() inspection?: Inspection;
  @Input() disabled = false;
  @Output() move = new EventEmitter<number>();
  @Output() openImage = new EventEmitter<string>();
  @Output() deleteChild = new EventEmitter<InspectionTemplateQuestion>();
  protected readonly QuestionType = QuestionType;
  protected readonly InspectionTemplatePropertyType = InspectionTemplatePropertyType;
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-information-container',
    templateUrl: './information-container.component.html',
    styleUrls: ['./information-container.component.scss'],
    standalone: false
})
export class InformationContainerComponent {
  @Input() availableHeight = 0;
}

import { Component, OnInit } from '@angular/core';
import { ProjectDto, ProjectsService, ProjectStatus } from '@api-clients/project';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UserService } from '@services/user.service';
import { ToastrService } from '@shared/services/toastr.service';
import { lastValueFrom } from 'rxjs';
import { fromDateOnly, toDateOnly } from '@shared/components/date-input/date-input.component';

export interface ProjectDetailForm {
  id: FormControl<string | undefined>;
  organizationId: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string | null>;
  project_number: FormControl<string | null>;
  subjects: FormControl<string>;
  start_date_utc: FormControl<Date | null>;
  end_date_utc: FormControl<Date | null>;
  status: FormControl<ProjectStatus>;
}

const required = Validators.required;

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrl: './project-detail.component.scss',
  standalone: false,
})
export class ProjectDetailComponent implements OnInit {
  protected breadcrumbTree = [
    {
      translate: 'project-module.project-overview-page',
      link: '/project',
    },
    { translate: 'project-module.project-detail-page', link: this.router.url },
  ];

  protected formGroup!: FormGroup<ProjectDetailForm>;
  protected isSaving: boolean = false;
  protected isNew: boolean = false;

  protected project!: ProjectDto;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly projectsService: ProjectsService,
    private readonly toastrService: ToastrService
  ) {
    this.buildForm();
  }

  protected buildForm(): void {
    this.formGroup = this.formBuilder.group<ProjectDetailForm>(
      {
        id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        organizationId: new FormControl<string>(this.userService.organizationId, {
          nonNullable: true,
        }),
        name: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        description: new FormControl('', {}),
        project_number: new FormControl('', {}),
        subjects: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        start_date_utc: new FormControl(null, {}),
        end_date_utc: new FormControl(null, {}),
        status: new FormControl<ProjectStatus>(ProjectStatus.Unknown, {
          nonNullable: true,
          validators: required,
        }),
      },
      { updateOn: 'submit', validators: [this.dateRangeValidator] }
    );
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (next) => {
      const id = next.get('id');

      this.isNew = id === null;

      if (id) {
        // If the id is set, we are updating a user
        this.formGroup.get('organizationId')!.disable();
        this.project = await lastValueFrom(this.projectsService.projectsIdGet(id));

        this.formGroup.patchValue({
          name: this.project.name,
          description: this.project.description,
          organizationId: this.project.organization_id,
          subjects: JSON.stringify(this.project.subjects),
          id: this.project.id,
          start_date_utc: fromDateOnly(this.project.start_date_utc),
          end_date_utc: fromDateOnly(this.project.end_date_utc),
          status: this.project.status,
          project_number: this.project.project_number,
        });
      } else {
        this.formGroup.patchValue({
          name: '',
          description: '',
          organizationId: undefined,
          subjects: '[]',
          id: '',
          start_date_utc: null,
          end_date_utc: null,
          status: ProjectStatus.Unknown,
          project_number: '',
        });
      }
    });
  }

  async save(): Promise<void> {
    if (!this.formGroup.valid) return;
    if (this.isSaving) return;

    this.isSaving = true;

    const project = this.formGroup.getRawValue();
    console.warn('save project', project);

    const request = {
      name: project.name,
      description: project.description ?? undefined,
      subjects: JSON.parse(project.subjects),
      start_date_utc: toDateOnly(project.start_date_utc) ?? undefined,
      end_date_utc: toDateOnly(project.end_date_utc) ?? undefined,
      status: project.status,
      project_number: project.project_number ?? undefined,
    };

    // If we have an ID, we are updating a user. If we have no ID, we are creating a new one.
    const putOrPost = project.id
      ? this.projectsService.projectsIdPut(project.id, request)
      : this.projectsService.projectsPost(request);

    putOrPost.subscribe((next) => {
      this.toastrService.showSuccess(
        'project-module.project.save-success',
        'project-module.project.save-title'
      );
      this.isSaving = false;
      this.project = next;
      this.router.navigate(['project']);
    });
  }

  dateRangeValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
    const startDate = form.get('start_date_utc')?.value;
    const endDate = form.get('end_date_utc')?.value;

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      return { dateRangeInvalid: true }; // Validation fails
    }

    return null; // Validation passes
  };

  protected readonly ProjectStatus = ProjectStatus;
  protected readonly Object = Object;
}

import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    standalone: false
})
export class UnauthorizedComponent implements OnInit {
  constructor(private authService: OidcSecurityService) {}

  ngOnInit(): void {
    this.authService.authorize();
  }
}

import { Component } from '@angular/core';
import {
  ProjectListItem,
  ProjectSortColumn,
  ProjectsService,
  SortOrder,
} from '@api-clients/project';
import { Router } from '@angular/router';
import { Scope } from '../../../scopes';
import { Resource } from '../../../resources';
import { getEnumValue } from '../../../building-module/utils';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrl: './project-overview.component.scss',
  standalone: false,
})
export class ProjectOverviewComponent {
  protected defaultPageSize: number = 10;
  protected sortBy: ProjectSortColumn;
  protected sortOrder: SortOrder;
  protected searchText: string = '';
  protected filteredData: ProjectListItem[] = [];
  protected totalCount: number = 0;
  protected page: number = 1;
  protected pageSize: number = this.defaultPageSize;
  private selectedProjectId?: string;
  protected breadcrumbTree = [
    {
      translate: 'project-module.project-overview-page',
      link: '/project',
    },
  ];

  // column data is added in html through a loop.
  protected columns: { key: ProjectSortColumn; label: string }[] = [
    { key: ProjectSortColumn.Name, label: 'name' },
    { key: ProjectSortColumn.Description, label: 'description' },
    { key: ProjectSortColumn.CreationTimestampUtc, label: 'creation-timestamp' },
    { key: ProjectSortColumn.LastModifiedTimestampUtc, label: 'last-modified-timestamp' },
  ];

  protected readonly SortOrder = SortOrder;

  constructor(
    private readonly router: Router,
    private readonly projectsService: ProjectsService,
    private readonly confirmationDialogService: ConfirmationDialogService
  ) {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.page = 1;
    this.pageSize = this.defaultPageSize;

    const sort_by = getEnumValue(
      ProjectSortColumn,
      localStorage.getItem('project-overview-sort-by')
    );
    this.sortBy = sort_by ?? ProjectSortColumn.Name;
    const sort_order = getEnumValue(SortOrder, localStorage.getItem('project-overview-sort-order'));
    this.sortOrder = sort_order || SortOrder.Asc;

    this.load();
  }

  load(): void {
    this.projectsService
      .projectsGet(this.page - 1, this.pageSize, this.sortBy, this.sortOrder, this.searchText)
      .subscribe((data) => {
        this.filteredData = data.items;
        this.totalCount = data.count;
      });
  }

  onPageChange(event: { page: number; pageSize: number }): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.load();
  }

  toggleSort(property: ProjectSortColumn | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? ProjectSortColumn.Name;
      this.sortOrder = SortOrder.Asc;
    }
    localStorage.setItem('project-overview-sort-by', this.sortBy);
    localStorage.setItem('project-overview-sort-order', this.sortOrder);
    this.load();
  }

  openDetail(item: ProjectListItem): void {
    void this.router.navigate(['project', item.id]);
  }

  search(): void {
    this.load();
  }

  createProject(): void {
    void this.router.navigate(['project', 'new']);
  }

  projectToRemovePopup(event: MouseEvent, projectId?: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (!projectId) return;
    this.selectedProjectId = projectId;
    this.confirmationDialogService.open();
  }

  removeProject(): void {
    if (!this.selectedProjectId) return;
    this.projectsService.projectsIdDelete(this.selectedProjectId).subscribe(() => {
      this.load();
    });
  }

  protected readonly Scope = Scope;
  protected readonly Resource = Resource;
}

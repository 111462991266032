<div class="dashboard-tile" [ngClass]="tileClass" cdkDrag>
  <div class="dashboard-tile__drag" cdkDragHandle>
    <span class="material-symbols-rounded">drag_indicator</span>
  </div>
  <h3 *ngIf="title">{{ title | translate }}</h3>

  <div class="dashboard-tile__content">
    <ng-content></ng-content>
  </div>
</div>

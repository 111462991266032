import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/// this validator validates whether the underlying formcontrol (which is a string) is not equal to an empty array.
export function createNonJsonArrayValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const jsonValie = value !== '[]';

    return !jsonValie ? { subjects: true } : null;
  };
}

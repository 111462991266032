import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, map, skip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ThreedComponent } from '@shared/components/threed/threed.component';
import { BimPropertyService } from '../../services';
import { BimService, BuildingSortColumn, SortOrder } from '@api-clients/bim';
import { ReferenceType } from '@api-clients/bim/model/referenceType';

@Component({
    selector: 'app-model-viewer',
    templateUrl: './model-viewer.component.html',
    styleUrls: ['./model-viewer.component.scss'],
    standalone: false
})
export class ModelViewerComponent implements OnInit {
  @ViewChild('threed') private threed!: ThreedComponent;
  @ViewChild('topBar') private topBar: ElementRef<HTMLElement> | undefined;

  protected bimId: string | undefined;

  // Only set by the navigate() function
  protected visibleMenu: 'overview' | 'changes' | 'details' = 'overview';
  protected availableHeight: number = 0;

  public shoppingCartCount: number = 0;
  protected bounceShoppingCartButton: boolean = false;

  protected breadcrumbTree: { name: string; link: string }[] = [];

  protected isSaving: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private readonly bimPropertyService: BimPropertyService,
    private readonly bimService: BimService
  ) {
    const bim_id = activatedRoute.parent?.snapshot.paramMap.get('bim_id');
    const real_estate_building_id =
      activatedRoute.parent?.snapshot.paramMap.get('real_estate_building_id');
    if (bim_id) {
      bimPropertyService.setBimId(bim_id);
      this.updateBreadCrumb();
      this.bimId = bim_id;
    } else if (real_estate_building_id) {
      this.bimService
        .bimGet(
          0,
          1,
          BuildingSortColumn.Description,
          SortOrder.Desc,
          undefined,
          undefined,
          undefined,
          {
            optional_BimSubject_id: real_estate_building_id,
            optional_BimSubject_type: ReferenceType.RealEstateBuilding,
          }
        )
        .subscribe((bim) => {
          if (bim.items.length === 0) {
            return;
          }

          this.bimId = bim.items[0].id;
          bimPropertyService.setBimId(this.bimId);
          this.updateBreadCrumb();
        });
    }

    // Keep track of the current menu state
    this.activatedRoute.paramMap
      .pipe(
        takeUntilDestroyed(),
        map((paramMap) => paramMap.get('state')),
        distinctUntilChanged()
      )
      .subscribe((state: string | null) => {
        if (state === 'changes') {
          if (this.shoppingCartCount > 0) this.visibleMenu = 'changes';
          else this.navigate('overview');
        } else if (state === 'details') {
          this.visibleMenu = 'details';
        } else if (state === 'overview') {
          this.visibleMenu = 'overview';
          this.bimPropertyService.selectBimElement(undefined);
        }
        this.updateBreadCrumb();
      });

    this.bimPropertyService.selectedElement
      .pipe(takeUntilDestroyed())
      .subscribe((selectedElement) => {
        if (selectedElement) {
          this.navigate('details');
        } else {
          this.navigate('overview');
        }
      });

    // Todo: this button logic should be moved to a separate component
    this.bimPropertyService.changeCount.pipe(skip(1)).subscribe((count) => {
      if (count > 0) {
        this.bounceShoppingCartButton = true;
        setTimeout(() => {
          this.bounceShoppingCartButton = false;
        }, 400);
      }
    });

    this.bimPropertyService.changeCount.subscribe((count) => {
      this.shoppingCartCount = count;
      if (this.shoppingCartCount == 0 && this.visibleMenu === 'changes') {
        this.navigate('overview');
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.updateBreadCrumb();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateAvailableHeight();
  }

  calculateAvailableHeight(extraHeight = 18): void {
    const topBar = this.topBar ? this.topBar.nativeElement.clientHeight : 0;
    this.availableHeight = window.innerHeight - topBar - extraHeight;
  }

  updateBreadCrumb(): void {
    const root = this.activatedRoute.snapshot.parent?.url.join('/');
    this.breadcrumbTree = [];
    this.breadcrumbTree.push({
      name: this.translateService.instant('building_information'),
      link: '/' + root + '/model/overview',
    });
    if (this.visibleMenu === 'changes' || this.visibleMenu === 'details') {
      this.breadcrumbTree.push({
        name: this.translateService.instant(this.visibleMenu),
        link: '/' + root + '/model/' + this.visibleMenu,
      });
    }

    this.calculateAvailableHeight();
  }

  async showShoppingCart(): Promise<void> {
    this.bimPropertyService.selectBimElement(undefined);
    this.navigate('changes');
  }

  async saveShoppingCart(): Promise<void> {
    if (this.isSaving) return;
    this.isSaving = true;
    await this.bimPropertyService.submitChanges();
    this.toastr.success(this.translateService.instant('changes_saved'));
    this.isSaving = false;
    this.navigate('overview');
  }

  navigate(menu: 'overview' | 'changes' | 'details'): void {
    void this.router.navigate(['../' + menu], { relativeTo: this.activatedRoute });
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Evented, LayerSpecification, Map, VectorSourceSpecification } from 'maplibre-gl';
import { environment } from '@env/environment';
import { Map3DLayerComponent } from '@shared/components/viewer/map-3d/map-3d-layer';

import { colorRuleMap, eq } from './color-map';
import { layers } from './ut-layers';
import { FlatNode, LegendComponent } from './legend/legend.component';
import { PerspectiveCamera } from 'three';
import { ActivatedRoute, Params, Router } from '@angular/router';

export const hdModelLayerId = 'custom-3d-model';
export const bag3DLayerId = 'bag-3d-models';

@Component({
  selector: 'map-2d',
  templateUrl: './map-2d.component.html',
  styleUrls: ['./map-2d.component.scss'],
  standalone: false,
})
export class Map2DComponent implements AfterViewInit, OnDestroy {
  map!: Map;
  gisLayers: Evented[] = [];

  legend = layers.filter((layer) => !layer.disabled);
  camera!: PerspectiveCamera;
  initialState = { lat: 52.242789, lng: 6.849455, zoom: 16 }; // UT
  @Input({ required: true }) map3dLayer!: Map3DLayerComponent;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  @ViewChild('legendControl')
  private legendControl!: LegendComponent;

  showBimLayer = true;
  showBag3DLayer = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  ngAfterViewInit(): void {
    const queryMap = this.activatedRoute.snapshot.queryParams;
    const lat = parseFloat(queryMap['lat'] || this.initialState.lat.toString());
    const lng = parseFloat(queryMap['lng'] || this.initialState.lng.toString());
    const zoom = parseFloat(queryMap['zoom'] || this.initialState.zoom.toString());

    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: `https://api.maptiler.com/maps/57feab4f-e013-4079-b901-0ac7b678f6e9/style.json?key=XCli4HbSVDG3WCLxU24r`,
      center: [lng, lat],
      maxPitch: 45,
      zoom,
      boxZoom: false, // we want to use shift for multiselection
    });

    this.route.queryParams.subscribe(this.navigateToLocation.bind(this));
    if (this.map.isStyleLoaded()) {
      this.loadLayers(this.map);
    } else {
      this.map.once('styledata', () => this.loadLayers(this.map));
    }

    this.map.on('resize', () => this.map3dLayer.updateProjectionMatrix(this.map));
    this.map.on('click', this.map3dLayer.handleMouseClick.bind(this.map3dLayer));
    this.map.on('zoomend', () => this.setRouteToCurrentLocation());
    this.map.on('error', (e) => console.warn('MapLibre error', e));
    this.map.on('dragend', () => this.setRouteToCurrentLocation());
  }

  loadLayers(map: Map): void {
    console.log(map);
    map.once('styledata', () => {
      layers
        .filter((layer) => !this.mapLayers.includes(layer.id))
        .forEach(({ id, type }) => {
          map.addSource(id, {
            url: `${environment.TREX_TILESERVER}/${id}.json`,
            type: 'vector',
          } as VectorSourceSpecification);

          const colorProp = type === 'fill' ? 'fill-color' : 'line-color';
          const layer = {
            id,
            source: id,
            'source-layer': id,
            paint:
              type === 'circle'
                ? {
                    'circle-color': '#ff0000', // Use 'circle-color' instead of 'line-color'
                    'circle-radius': 5,
                  }
                : {
                    [colorProp]: ['case', ...colorRuleMap, 'transparent'],
                  },
            type: type || 'line',
            visibility: 'visible',
          } as LayerSpecification;

          map.addLayer(layer);
          const styleLayer = map.getLayer(layer.id)!;
          this.gisLayers.push(styleLayer);
        });

      map.addLayer(this.map3dLayer);
      this.refreshVisibility();
    });
  }

  layerVisibility(layerId: string): string {
    return this.legendControl.selection.includes(layerId) ? 'visible' : 'none';
  }

  get mapLayers(): string[] {
    return layers.filter((layer) => layer.isMapLayer).map((layer) => layer.id);
  }

  get allLayers(): string[] {
    return [...this.gisLayers.map((layer) => layer['id']), ...this.mapLayers];
  }

  navigateToLocation(params: Params): void {
    const lat = Number(params['lat']);
    const lng = Number(params['lng']);
    const zoom = Number(params['zoom']);
    const [mapLat, mapLng] = this.map.getCenter().toArray();
    if (lat && lng && (Math.abs(lat - mapLat) > 1e-5 || Math.abs(lng - mapLng) > 1e-5))
      this.map.setCenter([lng, lat]);
    if (zoom && Math.abs(Number(params['zoom']) - this.map.getZoom()) > 0.2) this.map.setZoom(zoom);
  }

  setRouteToCurrentLocation(): void {
    const { lng, lat } = this.map.getCenter();
    const zoom = this.map.getZoom();
    this.ngZone.run(() => {
      void this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          lng: lng.toFixed(6),
          lat: lat.toFixed(6),
          zoom: zoom.toFixed(1),
        },
        queryParamsHandling: 'merge',
      });
    });
  }

  setSelected(selected: FlatNode[]): void {
    const selectedIds = selected.map((node) => [eq(node?.postGISColumn || 'thema', node.id), 1]);
    selectedIds.push([eq('thema', 'dummy'), 1]);
    /*    this.gisLayers.forEach((layer) => {
/!*      const prop =
        layer.type === 'fill'
          ? 'fill-opacity'
          : layer.type === 'circle'
            ? 'circle-opacity'
            : 'line-opacity';*!/
      //this.map?.setPaintProperty(layer.id, prop, ['case', ...selectedIds.flat(), 0]);
    });*/
    this.refreshVisibility();
  }

  refreshVisibility(): void {
    const selectedIds = this.legendControl.selection;
    this.allLayers
      .filter((id) => id != hdModelLayerId && id != bag3DLayerId) // Don't hide 3D layers
      .forEach((layerId) => {
        const visible = selectedIds.includes(layerId) ? 'visible' : 'none';
        this.map?.setLayoutProperty(layerId, 'visibility', visible);
      });
    this.showBimLayer = selectedIds.includes(hdModelLayerId);
    this.showBag3DLayer = selectedIds.includes(bag3DLayerId);
    //this.mapService.reloadOwnedBuildings();
  }

  ngOnDestroy(): void {
    this.map?.remove();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import { InspectionFinding, InspectionTemplateQuestion } from '@api-clients/dossier';
import { isCheckListAnswer, isRadioListAnswer } from '../../../building-module/utils';

@Component({
    selector: 'app-inspection-checklist',
    templateUrl: './checklist.component.html',
    styleUrl: './checklist.component.scss',
    standalone: false
})
export class ChecklistComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  potentialFieldName: string = '';
  menu: ContextMenuItem[] = [];

  protected readonly isCheckListAnswer = isCheckListAnswer;
  protected readonly isRadioListAnswer = isRadioListAnswer;

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
      {
        divider: true,
      },
    ];
  }

  get radio(): boolean {
    return this.question.property.property_type === 'RadioList' || false;
  }

  get fields(): string[] {
    return this.question.property.possible_values || [];
  }

  set fields(fields: string[]) {
    this.question.property.possible_values = fields;
  }

  addField(): void {
    this.fields.push(this.potentialFieldName);
    this.potentialFieldName = '';
  }

  removeField(field: string): void {
    this.fields = this.fields.filter((otherField) => otherField != field);
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  trackByFn(index: number): number {
    return index;
  }

  alterValue(field: string): void {
    const finding = this.question as InspectionFinding;
    if (finding === undefined) return;
    if (finding.value === undefined || finding.value === null) {
      if (this.radio) {
        finding.value = { type: 'RadioList', value: field };
      } else {
        finding.value = { type: 'CheckList', values: [field] };
      }
      return;
    }
    if (this.isCheckListAnswer(finding.value)) {
      const values = finding.value.values;
      if (values.includes(field)) {
        finding.value.values = values.filter((value) => value !== field);
      } else {
        finding.value.values = [...values, field];
      }
    }
    if (this.isRadioListAnswer(finding.value)) {
      finding.value.value = field;
    }
  }

  isChecked(field: string): boolean {
    const finding = this.question as InspectionFinding;
    if (finding === undefined || finding.value === undefined || finding.value === null)
      return false;
    if (this.isCheckListAnswer(finding.value)) {
      const values = finding.value.values;
      if (values.includes(field)) {
        return true;
      }
      return false;
    }
    if (this.isRadioListAnswer(finding.value)) {
      return finding.value.value === field;
    }
    return false;
  }
}

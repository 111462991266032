import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasureDto, MeasuresService } from '@api-clients/project';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ToastrService } from '@shared/services/toastr.service';
import { FormulaService } from '../../services/formula.service';
import { lastValueFrom } from 'rxjs';
import { createNonJsonArrayValidator } from '../../validators/empty-array-validator';

export interface MeasureForm {
  id: FormControl<string | undefined>;
  organizationId: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  variables: FormArray;
  calculations: FormArray;
  subjects: FormControl<string>;
}

const required = Validators.required;

@Component({
  selector: 'app-measure-detail',
  templateUrl: './measure-detail.component.html',
  styleUrl: './measure-detail.component.scss',
  standalone: false,
})
export class MeasureDetailComponent implements OnInit {
  protected breadcrumbTree = [
    {
      translate: 'project-module.measure.overview-page',
      link: '/measure',
    },
    { translate: 'project-module.measure.page', link: this.router.url },
  ];

  protected formGroup!: FormGroup<MeasureForm>;
  protected isSaving: boolean = false;
  protected isNew: boolean = false;

  protected measure!: MeasureDto;
  protected lastCalculationError: string = '';
  protected lastCalculationVariables: string = '';
  protected lastCalculationOutputMin: number = 0;
  protected lastCalculationOutputMax: number = 0;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly measuresService: MeasuresService,
    private readonly toastrService: ToastrService,
    private readonly formulaService: FormulaService
  ) {
    this.buildForm();
  }

  protected buildForm(): void {
    this.formGroup = this.formBuilder.group<MeasureForm>(
      {
        id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        organizationId: new FormControl<string>(this.userService.organizationId, {
          nonNullable: true,
        }),
        name: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        description: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        variables: this.formBuilder.array([]),
        calculations: this.formBuilder.array([]),
        subjects: new FormControl('', {
          nonNullable: true,
          validators: [required, createNonJsonArrayValidator()],
        }),
      },
      { updateOn: 'submit' }
    );
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (next) => {
      const id = next.get('id');

      this.isNew = id === null;

      if (id) {
        // If the id is set, we are updating a user
        this.formGroup.get('organizationId')!.disable();
        this.measure = await lastValueFrom(this.measuresService.measuresIdGet(id));

        // this is necessary for the initial creation of controls
        for (let i = 0; i < this.measure.variables.length; i++) {
          this.addVariable();
        }

        // this is necessary for the initial creation of controls
        for (let i = 0; i < this.measure.calculations.length; i++) {
          this.addCalculation();
        }

        this.formGroup.patchValue({
          name: this.measure.name,
          description: this.measure.description,
          variables: this.measure.variables,
          calculations: this.measure.calculations,
          organizationId: this.measure.organization_id,
          subjects: JSON.stringify(this.measure.subjects),
          id: this.measure.id,
        });
      }
    });
  }

  protected addVariable(): void {
    const fa = this.formGroup.get('variables') as FormArray;
    fa.push(
      this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        unit: [''],
      })
    );
  }

  protected deleteVariable(index: number): void {
    const fa = this.formGroup.get('variables') as FormArray;
    fa.removeAt(index);
  }

  protected addCalculation(): void {
    const fa = this.formGroup.get('calculations') as FormArray;
    fa.push(
      this.formBuilder.group({
        name: ['', Validators.required],
        unit: ['', Validators.required],
        formula: ['', Validators.required],
      })
    );
  }

  protected deleteCalculation(index: number): void {
    const fa = this.formGroup.get('calculations') as FormArray;
    fa.removeAt(index);
  }

  protected executeFormula(evt: Event, index: number): void {
    const fa = this.formGroup.get('calculations') as FormArray;
    fa
      .at(index)
      .get('formula')
      ?.setValue((evt.target as HTMLInputElement).value);
    const formula = fa.at(index).get('formula')?.value;

    const measure_detail = this.formGroup.getRawValue();

    const variables = Object.fromEntries(
      measure_detail.variables.map((item, index) => [item.name, index + 1])
    );

    this.lastCalculationVariables = JSON.stringify(variables);

    const result = this.formulaService.evaluateFormula(formula, variables);

    this.lastCalculationOutputMin = result.min;
    this.lastCalculationOutputMax = result.max;
    this.lastCalculationError = result.error || '';
  }

  getVariables(index: number): Record<string, number> {
    const measure_detail = this.formGroup.getRawValue();

    return Object.fromEntries(measure_detail.variables.map((item) => [item.name, index + 1]));
  }

  async save(): Promise<void> {
    if (!this.formGroup.valid) return;
    if (this.isSaving) return;

    this.isSaving = true;

    const measure_detail = this.formGroup.getRawValue();

    console.warn('save', measure_detail);

    // If we have an ID, we are updating a user. If we have no ID, we are creating a new one.
    const putOrPost = measure_detail.id
      ? this.measuresService.measuresIdPut(measure_detail.id, {
          subjects: JSON.parse(measure_detail.subjects),
          name: measure_detail.name,
          description: measure_detail.description,
          variables: measure_detail.variables,
          calculations: measure_detail.calculations,
        })
      : this.measuresService.measuresPost({
          subjects: JSON.parse(measure_detail.subjects),
          name: measure_detail.name,
          description: measure_detail.description,
          variables: measure_detail.variables,
          calculations: measure_detail.calculations,
        });
    putOrPost.subscribe(() => {
      this.toastrService.showSuccess(
        'project-module.measure.save-success',
        'project-module.measure.save-title'
      );
      this.isSaving = false;
      this.router.navigate(['measure']);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasureTemplateDetailComponent } from './views/measure-template-detail/measure-template-detail.component';
import { ProjectRoutingModule } from './project-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MeasureTemplateOverviewComponent } from './views/measure-template-overview/measure-template-overview.component';
import { AuthConfigModule } from '../auth/auth-config.module';
import { ProjectOverviewComponent } from './views/project-overview/project-overview.component';
import { ProjectDetailComponent } from './views/project-detail/project-detail.component';
import { GenericSubjectEditComponent } from './components/generic-subject-edit/generic-subject-edit.component';
import { MatChipGrid, MatChipInput, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { CalculationPanelComponent } from './components/calculation-pane/calculation-panel.component';
import { MeasureOverviewComponent } from './views/measure-overview/measure-overview.component';
import { MeasureDetailComponent } from './views/measure-detail/measure-detail.component';
import { MeasureSubjectEditComponent } from './components/measure-subject-edit/measure-subject-edit.component';
import { MeasureCopyPopupComponent } from './components/measure-copy-popup/measure-copy-popup.component';
import { InspectionModule } from '../inspection-module/inspection.module';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ProjectDashboardComponent } from './views/dashboard/dashboard.component';
import { ProjectDashboardTileComponent } from './views/dashboard/dashboard-tile/dashboard-tile.component';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    MeasureTemplateOverviewComponent,
    MeasureTemplateDetailComponent,
    ProjectOverviewComponent,
    ProjectDetailComponent,
    GenericSubjectEditComponent,
    CalculationPanelComponent,
    GenericSubjectEditComponent,
    MeasureSubjectEditComponent,
    MeasureOverviewComponent,
    MeasureDetailComponent,
    MeasureCopyPopupComponent,
    ProjectDashboardComponent,
    ProjectDashboardTileComponent,
  ],
  imports: [
    CommonModule,
    ProjectRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    AuthConfigModule,
    MatChipGrid,
    MatChipRow,
    MatChipInput,
    MatChipRemove,
    InspectionModule,
    MatProgressSpinner,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
  ],
})
export class ProjectModule {}

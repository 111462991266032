import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BuildingMenuService } from '@shared/services/building-menu.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BuildingOverviewService } from '@services/building-overview.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { Subject, takeUntil } from 'rxjs';
import { PermissionService } from '../../../auth/permission-service';
import { NotePopupService } from '../../../building-module/components/note-popup/note-popup.service';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';

export interface menuItems {
  label?: string;
  icon?: string;
  route?: string[];
  subActive?: boolean;
  subMenu?: menuItems[];
}

@Component({
    selector: 'app-building-menu',
    templateUrl: './building-menu.component.html',
    styleUrls: ['./building-menu.component.scss'],
    standalone: false
})
export class BuildingMenuComponent implements OnChanges, OnDestroy, OnInit {
  @Input() buildingId?: string;
  @Output() expandMenu: EventEmitter<void> = new EventEmitter<void>();

  building?: BuildingOverviewEntry;
  expandState: boolean = localStorage.getItem('buildingMenuExpand') === 'true';
  active: boolean = false;

  menuItems: menuItems[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private readonly buildingMenuService: BuildingMenuService,
    private readonly buildingOverviewService: BuildingOverviewService,
    private readonly router: Router,
    private readonly notePopupService: NotePopupService,
    private readonly permissionService: PermissionService
  ) {
    this.buildingMenuService.buildingMenuStatus$.pipe(takeUntilDestroyed()).subscribe((status) => {
      this.active = status.active;
    });
  }

  ngOnInit(): void {
    this.menuItems = [
      {
        label: 'inspections',
        icon: 'article',
        route: ['inspections'],
        subActive: true,
      },
      {
        label: '3d-model',
        icon: 'deployed_code',
        route: ['model', 'overview'],
      },
      {
        label: 'floor-plan',
        icon: 'floor',
        route: ['floor-plan', 'details'],
      },
    ];
    if (this.permissionService.userHasPermissionWithScope(Resource.Dossiers, Scope.READ))
      this.menuItems.push({
        label: 'dossier',
        icon: 'Package',
        route: ['dossier'],
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['buildingId']) {
      this.buildingOverviewService
        .getBuildingByRealEstateId(changes['buildingId'].currentValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe((next) => {
          this.building = next;
        });
    }
  }

  triggerNotePopup(): void {
    this.notePopupService.show();
  }

  navigate(routeParts?: string[]): void {
    if (routeParts === undefined) {
      return;
    }
    void this.router.navigate(['buildings', this.buildingId, ...routeParts]);
  }

  toggleExpand(): void {
    this.buildingMenuService.toggleExpand();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly localStorage = localStorage;
}

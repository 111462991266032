import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, lastValueFrom } from 'rxjs';
import { AdresService } from '@api-clients/kadaster/api/adres.service';
import { AdresIOHal } from '@api-clients/kadaster/model/adresIOHal';

interface GeocodeResult {
  street?: string;
  postal?: string;
  place?: string;
  id: string;
}

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent implements OnInit {
  @ViewChild('addressInput') private addressInput!: ElementRef;
  addressFormControl: FormControl = new FormControl('');
  geocodeResult: GeocodeResult[] = [];
  barActive = false;
  @Output() addressSelected$ = new EventEmitter<GeocodeResult>();

  constructor(private adresService: AdresService) {}

  ngOnInit(): void {
    this.addressFormControl.valueChanges.pipe(debounceTime(500)).subscribe(async (value) => {
      const result = await this.searchCadastral(value);
      this.geocodeResult = result
        .filter((r) => r.pandIdentificaties?.[0])
        .map((r) => ({
          street: r.adresregel5,
          postal: r.adresregel6,
          place: r.adresregel5 + ', ' + r.adresregel6,
          id: r.pandIdentificaties![0],
        }));
    });
  }

  // Close on escape and don't submit on enter input
  onSearchKeydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.endSearch();
    } else if (event.code === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  selectAddress(e: MatAutocompleteSelectedEvent): void {
    this.endSearch();
    this.addressSelected$.emit(e.option);
  }

  endSearch(): void {
    this.addressInput.nativeElement.blur();
    this.barActive = false;
  }

  switchBar(): void {
    this.barActive = !this.barActive;
  }

  async searchCadastral(searchValue): Promise<AdresIOHal[]> {
    if (searchValue.length > 3) {
      const params = {
        q: searchValue,
        page: 1,
        pageSize: 10,
      };

      const search = await lastValueFrom(this.adresService.bevraagAdressen(params));
      return search._embedded?.adressen || [];
    }
    return [];
  }
}

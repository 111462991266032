import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MeasureSubject, MeasureSubjectRealEstateBuildingReference } from '@api-clients/project';
import { BehaviorSubject } from 'rxjs';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { BuildingOverviewService } from '@services/building-overview.service';

@Component({
  selector: 'app-measure-subject-edit',
  templateUrl: './measure-subject-edit.component.html',
  styleUrl: './measure-subject-edit.component.scss',
  standalone: false,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MeasureSubjectEditComponent),
      multi: true,
    },
  ],
})
export class MeasureSubjectEditComponent implements ControlValueAccessor {
  @Input({ required: true }) formControlName!: string;
  @Input({ required: true }) id!: string;
  @Input() type = 'text'; // Default type is text
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  protected value: MeasureSubject[] = [];
  protected filteredBuildings$: BehaviorSubject<BuildingOverviewEntry[]> = new BehaviorSubject<
    BuildingOverviewEntry[]
  >([]);

  protected readonly formControl = new FormControl([this.formControlName]);

  protected buildings: Map<string, BuildingOverviewEntry> = new Map();

  constructor(private readonly buildingOverviewService: BuildingOverviewService) {
    this.buildingOverviewService.ownedBuildings$.subscribe((ownedBuildings) => {
      this.buildings = new Map(
        ownedBuildings.map((building) => [building.real_estate_id!, building])
      );
    });
  }

  add(entry: BuildingOverviewEntry): void {
    //if (this.isBuilding(entry)) {
    const real_estate_id = entry.real_estate_id;
    if (
      this.value.find(
        (v) => this.isRealEstateBuildingReference(v) && v.real_estate_building_id === real_estate_id
      )
    )
      return;
    this.value.push(<MeasureSubjectRealEstateBuildingReference>{
      real_estate_building_id: real_estate_id,
      type: 'RealEstateBuilding',
    });
    //} else {
    //}
    this.onChange(JSON.stringify(this.value));
    this.input.nativeElement.value = '';
  }

  // Function that will be called when value changes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange = (value: any): void => {
    this.value = JSON.parse(value);
  };

  private onTouched = (): void => {};

  // Called when Angular wants to update the value
  async writeValue(value: string): Promise<void> {
    if (value) {
      this.value = JSON.parse(value);
    }
  }

  // Called when form control is changed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Called when form control is touched
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Called when component should be enabled/disabled
  setDisabledState?(isDisabled: boolean): void {
    console.warn('setDisabledState not implemented', isDisabled);
    // Handle disabling logic if necessary
  }

  remove(subject: MeasureSubject): void {
    this.value.splice(this.value.indexOf(subject), 1);
    this.onChange(JSON.stringify(this.value));
  }

  filterBuildings(input: string): BuildingOverviewEntry[] {
    if (!input) return Array.from(this.buildings.values());
    const lowercaseInput = input.toLowerCase();
    return Array.from(this.buildings.values()).filter((b) =>
      `${b.buildingMetadata.city} - ${b.buildingMetadata.address}`
        .toLowerCase()
        .includes(lowercaseInput)
    );
  }

  inputChange(evt: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterText = (evt.target as any).value;
    const filteredBuildings = this.filterBuildings(filterText);
    this.filteredBuildings$.next(filteredBuildings);
  }

  // Type Guard function to check if it's a BuildingOverviewEntry
  isBuilding(entry: BuildingOverviewEntry): entry is BuildingOverviewEntry {
    return (entry as BuildingOverviewEntry).real_estate_id !== undefined;
  }

  isRealEstateBuildingReference(
    entry: MeasureSubject
  ): entry is MeasureSubjectRealEstateBuildingReference {
    return (
      (entry as MeasureSubjectRealEstateBuildingReference).real_estate_building_id !== undefined
    );
  }
}

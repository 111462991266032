// kebab-case.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
@Pipe({
    name: 'visible',
    pure: false,
    standalone: false
})
export class VisiblePipe implements PipeTransform {
  transform(items: ContextMenuItem[]): ContextMenuItem[] {
    return items.filter((item) =>
      typeof item.visible !== 'function' ? item.visible !== false : item.visible()
    );
  }
}

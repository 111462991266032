import { Component, EventEmitter, Output } from '@angular/core';
import { WorkspaceDetailService } from '../../services/workspace-detail.service';

@Component({
    selector: 'app-workspace-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    standalone: false
})
export class WorkspaceTopBarComponent {
  @Output() searchChange = new EventEmitter<string>();

  expanded = false;

  constructor(protected workspaceDetailService: WorkspaceDetailService) {}

  toggle(): void {
    this.expanded = !this.expanded;
  }

  changeSwitch(value): void {
    this.workspaceDetailService.edit.next(value);
  }

  async onSearchChange(event: Event): Promise<void> {
    const value = (event.target as HTMLInputElement).value;
    this.searchChange.emit(value); // Emitting the value to the parent component
  }
}

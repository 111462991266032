<div class="inspection-template-detail">
  <div class="inspection-template-detail__container">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>


    <div class="intro">
      <h1 *ngIf="!edit">{{ inspectionTemplate.description }}</h1>

      <input
        class="h1 title-input"
        type="text"
        name="description"
        autocomplete="off"
        [(ngModel)]="inspectionTemplate!.description"
        placeholder="{{ 'dossier-module.inspection-template-title' | translate }}"
        *ngIf="edit" />

      <app-lightswitch
        [label]="'view-preview' | translate"
        [checked]="!edit"
        (changedState)="changeSwitch($event)"></app-lightswitch>

      <div class="inspection-template-detail__container__buttons">
        <button class="button-secondary button--has-icon" (click)="download()">
          <span class="material-symbols-rounded">download</span>
          {{ 'dossier-module.download' | translate }}
          <a #downloadAnchorElem style="display:none"></a>
        </button>

        <button (click)="save()" *ngIf="edit" [disabled]="isSaving" class="button-primary">
          <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
    <div cdkDropList cdkDropListGroup (cdkDropListDropped)="itemDropped($event)" class="item-list">
      <app-inspection-section
        *ngFor="let section of inspectionTemplate.definition.sections; let i = index"
        (click)="activateSection(section)"
        [active]="inspectionService.getCurrentSection() === section"
        [section]="section"
        [inspectionTemplate]="inspectionTemplate"
        [edit]="edit"
        [disabled]="!edit"
        [sectionIndex]="i"
        (deleteSection)="deleteSection(section)"></app-inspection-section>
    </div>

    <app-inspection-toolbox
      [edit]="edit"
      [currentSection]="inspectionService.getCurrentSection()"></app-inspection-toolbox>
  </div>
</div>

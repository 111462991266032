import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BuildingOverviewService } from '@services/building-overview.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'list-view',
    templateUrl: './list-view.component.html',
    styleUrl: './list-view.component.scss',
    standalone: false
})
export class ListViewComponent implements OnInit {
  breadcrumbTree = [
    {
      name: 'Woningportefeuille',
      link: '/',
    },
  ];

  defaultPageSize: number = 10;
  buildings: BuildingOverviewEntry[] = [];
  filteredBuildings: BuildingOverviewEntry[] = [];

  constructor(
    private readonly router: Router,
    private readonly buildingOverviewService: BuildingOverviewService
  ) {
    this.buildingOverviewService.ownedBuildings$.pipe(takeUntilDestroyed()).subscribe((next) => {
      this.buildings = next;
      this.updateBuildings(1, this.defaultPageSize);
    });
  }

  async ngOnInit(): Promise<void> {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
  }

  hasFiles(building): boolean {
    return building.metadata?.additionalFiles?.length > 0;
  }

  updateBuildings(page, size): void {
    this.filteredBuildings = this.buildings.slice((page - 1) * size, page * size);
  }

  onPageChange(event): void {
    this.updateBuildings(event.page, event.pageSize);
  }

  async navigateToModel(event, file): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/model-viewer'], {
        queryParams: { file },
      })
    );
    window.open(url, '_blank');
  }
}

/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateUpdateMeasureRequest } from '../model/createUpdateMeasureRequest';
// @ts-ignore
import { MeasureDto } from '../model/measureDto';
// @ts-ignore
import { MeasureListResponse } from '../model/measureListResponse';
// @ts-ignore
import { MeasureSortColumn } from '../model/measureSortColumn';
// @ts-ignore
import { OptionalMeasureSubjectQuery } from '../model/optionalMeasureSubjectQuery';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MeasuresService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Retrieve all measures that match the search criteria.
     * # Query Parameters: - &#x60;scenario_id&#x60; (optional): Filter measures by scenario ID. - &#x60;filter_text&#x60; (optional): A search string to filter measures by name or description. - &#x60;page_index&#x60;: The index of the page to retrieve (for pagination). - &#x60;page_size&#x60;: The number of measures to return per page. - &#x60;sort_column&#x60;: The column by which results should be sorted. Allowed values: &#x60;\&quot;ID\&quot;&#x60;, &#x60;\&quot;Name\&quot;&#x60;, &#x60;\&quot;CreationTimestampUtc\&quot;&#x60;. - &#x60;sort_order&#x60;: The sorting order. Allowed values: &#x60;\&quot;Asc\&quot;&#x60;, &#x60;\&quot;Desc\&quot;&#x60;.  # Responses: - **200 OK:** Returns a JSON array of measures. # Error Responses: - **500 Internal Server Error:** If there is a server error during the retrieval process. # Example Response Body (200 OK): &#x60;&#x60;&#x60;json [{ \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;scenario_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;subjects\&quot;: [], \&quot;name\&quot;: \&quot;Measure Name\&quot;, \&quot;description\&quot;: \&quot;Measure Description\&quot;, \&quot;variables\&quot;: [], \&quot;calculations\&quot;: [] }] &#x60;&#x60;&#x60;
     * @param pageIndex 
     * @param pageSize 
     * @param sortColumn 
     * @param sortOrder 
     * @param scenarioId 
     * @param subject 
     * @param filterText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public measuresGet(pageIndex: number, pageSize: number, sortColumn: MeasureSortColumn, sortOrder: SortOrder, scenarioId?: string, subject?: OptionalMeasureSubjectQuery, filterText?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureListResponse>;
    public measuresGet(pageIndex: number, pageSize: number, sortColumn: MeasureSortColumn, sortOrder: SortOrder, scenarioId?: string, subject?: OptionalMeasureSubjectQuery, filterText?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureListResponse>>;
    public measuresGet(pageIndex: number, pageSize: number, sortColumn: MeasureSortColumn, sortOrder: SortOrder, scenarioId?: string, subject?: OptionalMeasureSubjectQuery, filterText?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureListResponse>>;
    public measuresGet(pageIndex: number, pageSize: number, sortColumn: MeasureSortColumn, sortOrder: SortOrder, scenarioId?: string, subject?: OptionalMeasureSubjectQuery, filterText?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (pageIndex === null || pageIndex === undefined) {
            throw new Error('Required parameter pageIndex was null or undefined when calling measuresGet.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling measuresGet.');
        }
        if (sortColumn === null || sortColumn === undefined) {
            throw new Error('Required parameter sortColumn was null or undefined when calling measuresGet.');
        }
        if (sortOrder === null || sortOrder === undefined) {
            throw new Error('Required parameter sortOrder was null or undefined when calling measuresGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (scenarioId !== undefined && scenarioId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scenarioId, 'scenario_id');
        }
        if (subject !== undefined && subject !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subject, 'subject');
        }
        if (filterText !== undefined && filterText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterText, 'filter_text');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'page_index');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortColumn, 'sort_column');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sort_order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measures`;
        return this.httpClient.request<MeasureListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing measure.
     * # Path Parameters: - &#x60;id&#x60;: The unique identifier of the measure. # Responses: - **204 No Content:** If the measure was successfully deleted. # Error Responses: - **404 Not Found:** If the specified measure is not found. - **500 Internal Server Error:** If there is a server error during the deletion process.
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public measuresIdDelete(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public measuresIdDelete(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public measuresIdDelete(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public measuresIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling measuresIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measures/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a specific measure by ID.
     * # Path Parameters: - &#x60;id&#x60;: The unique identifier of the measure. # Responses: - **200 OK:** Returns the requested measure. # Error Responses: - **404 Not Found:** If the specified measure cannot be found. - **500 Internal Server Error:** If there is a server error during the retrieval process. # Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;scenario_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;subjects\&quot;: [], \&quot;name\&quot;: \&quot;Measure Name\&quot;, \&quot;description\&quot;: \&quot;Measure Description\&quot;, \&quot;variables\&quot;: [], \&quot;calculations\&quot;: [] } &#x60;&#x60;&#x60;
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public measuresIdGet(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureDto>;
    public measuresIdGet(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureDto>>;
    public measuresIdGet(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureDto>>;
    public measuresIdGet(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling measuresIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measures/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<MeasureDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing measure.
     * # Path Parameters: - &#x60;id&#x60;: The unique identifier of the measure. # Responses: - **200 OK:** Returns the updated measure. # Error Responses: - **404 Not Found:** If the specified measure is not found. - **500 Internal Server Error:** If there is a server error during the update process. # Example Request Body: &#x60;&#x60;&#x60;json { \&quot;scenario_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;name\&quot;: \&quot;Updated Measure Name\&quot;, \&quot;description\&quot;: \&quot;Updated Measure Description\&quot;, \&quot;subjects\&quot;: [], \&quot;variables\&quot;: [], \&quot;calculations\&quot;: [] } &#x60;&#x60;&#x60; # Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;scenario_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;subjects\&quot;: [], \&quot;name\&quot;: \&quot;Updated Measure Name\&quot;, \&quot;description\&quot;: \&quot;Updated Measure Description\&quot;, \&quot;variables\&quot;: [], \&quot;calculations\&quot;: [] } &#x60;&#x60;&#x60;
     * @param id 
     * @param createUpdateMeasureRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public measuresIdPut(id: string, createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureDto>;
    public measuresIdPut(id: string, createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureDto>>;
    public measuresIdPut(id: string, createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureDto>>;
    public measuresIdPut(id: string, createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling measuresIdPut.');
        }
        if (createUpdateMeasureRequest === null || createUpdateMeasureRequest === undefined) {
            throw new Error('Required parameter createUpdateMeasureRequest was null or undefined when calling measuresIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measures/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<MeasureDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateMeasureRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new measure.
     * # Responses: - **201 Created:** Returns the created measure. # Error Responses: - **500 Internal Server Error:** If there is a server error during the creation process. # Example Request Body: &#x60;&#x60;&#x60;json { \&quot;scenario_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;name\&quot;: \&quot;Measure Name\&quot;, \&quot;description\&quot;: \&quot;Measure Description\&quot;, \&quot;subjects\&quot;: [], \&quot;variables\&quot;: [], \&quot;calculations\&quot;: [] } &#x60;&#x60;&#x60; # Example Response Body (201 Created): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;scenario_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;subjects\&quot;: [], \&quot;name\&quot;: \&quot;Measure Name\&quot;, \&quot;description\&quot;: \&quot;Measure Description\&quot;, \&quot;variables\&quot;: [], \&quot;calculations\&quot;: [] } &#x60;&#x60;&#x60;
     * @param createUpdateMeasureRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public measuresPost(createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureDto>;
    public measuresPost(createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureDto>>;
    public measuresPost(createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureDto>>;
    public measuresPost(createUpdateMeasureRequest: CreateUpdateMeasureRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (createUpdateMeasureRequest === null || createUpdateMeasureRequest === undefined) {
            throw new Error('Required parameter createUpdateMeasureRequest was null or undefined when calling measuresPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measures`;
        return this.httpClient.request<MeasureDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateMeasureRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

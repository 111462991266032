import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  standalone: false,
})
export class LandingPageComponent {
  locations = [
    {
      name: 'Assen',
      lng: 6.564406,
      lat: 53.002794,
      zoom: 14.3,
    },
    {
      name: 'Almelo',
      lng: 6.674257,
      lat: 52.349679,
      zoom: 14,
    },
    {
      name: 'UT',
      lng: 6.854087,
      lat: 52.242194,
      zoom: 15,
    },
    {
      name: 'Klooster',
      lng: 6.935617,
      lat: 52.215604,
      zoom: 17,
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  get listView(): boolean {
    return this.route.snapshot.queryParamMap.get('list') === 'true';
  }

  changeView(): void {
    void this.router.navigate([], {
      queryParams: { list: !this.listView },
      queryParamsHandling: 'merge',
    });
  }
}

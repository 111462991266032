<div class="measure-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'project-module.measure.page' | translate }}</h1>
    </div>
  </div>

  <form [formGroup]="formGroup" (submit)="save()" class="form">
    <div class="form-group">
      <label for="name">{{ 'name' | translate }}*</label>
      <input id="name" class="input" type="text" formControlName="name" />
      <div class="error" *ngIf="formGroup.controls.name.errors?.['required']">
        {{ 'name-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="description">{{ 'description' | translate }}*</label>
      <input id="description" class="input" type="text" formControlName="description" />
      <div class="error" *ngIf="formGroup.controls.description.errors?.['required']">
        {{ 'description-required-error' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label for="subjects">{{ 'project-module.attach-buildings' | translate }}*</label>
      <app-measure-subject-edit id="subjects" formControlName="subjects"></app-measure-subject-edit>
      <div
        class="error"
        *ngIf="
          formGroup.controls.subjects.errors?.['required'] ||
          formGroup.controls.subjects.value === '[]'
        ">
        {{ 'subjects-required-error' | translate }}
      </div>
    </div>

    <div class="form-group" formArrayName="variables">
      <div *ngFor="let field of formGroup.controls.variables.controls; let i = index">
        <div class="form-row" [formGroupName]="i">
          <div class="form-item">
            <label for="field_name">{{ 'name' | translate }}*</label>
            <input id="field_name" class="input" type="text" formControlName="name" />
            <div class="error" *ngIf="field.get('name')?.errors?.['required']">
              {{ 'name-required-error' | translate }}
            </div>
          </div>
          <div class="form-item">
            <label for="field_description">{{ 'description' | translate }}*</label>
            <input id="field_description" class="input" type="text" formControlName="description" />
            <div class="error" *ngIf="field.get('description')?.errors?.['required']">
              {{ 'description-required-error' | translate }}
            </div>
          </div>
          <div class="form-item">
            <label for="field_unit">{{ 'unit' | translate }}</label>
            <input id="field_unit" class="input" type="text" formControlName="unit" />
          </div>
          <button (click)="deleteVariable(i)">
            <span class="material-symbols-rounded">remove</span>
          </button>
        </div>
      </div>
      <button (click)="addVariable()">
        <span class="material-symbols-rounded">add</span>
        {{ 'project-module.measure.add-variable' | translate }}
      </button>
    </div>

    <div class="form-group" formArrayName="calculations">
      <div *ngFor="let field of formGroup.controls.calculations.controls; let i = index">
        <div class="form-row" [formGroupName]="i">
          <div class="form-item">
            <label for="field_calculation_name">{{ 'name' | translate }}*</label>
            <input id="field_calculation_name" class="input" type="text" formControlName="name" />
            <div class="error" *ngIf="field.get('name')?.errors?.['required']">
              {{ 'name-required-error' | translate }}
            </div>
          </div>
          <div class="form-item">
            <label for="field_calculation_unit">{{ 'unit' | translate }}*</label>
            <input id="field_calculation_unit" class="input" type="text" formControlName="unit" />
            <div class="error" *ngIf="field.get('unit')?.errors?.['required']">
              {{ 'unit-required-error' | translate }}
            </div>
          </div>
          <div class="form-item calculation-item">
            <label for="field_calculation_formula">{{ 'calculation' | translate }}*</label>
            <input
              id="field_calculation_formula"
              class="input"
              type="text"
              formControlName="formula" />
            <div class="error" *ngIf="field.get('formula')?.errors?.['required']">
              {{ 'project-module.calculation-required-error' | translate }}
            </div>
          </div>
          <button (click)="deleteCalculation(i)">
            <span class="material-symbols-rounded">remove</span>
          </button>
        </div>

        <app-calculation-panel
          [formula]="formGroup.controls.calculations.controls[i].value.formula"
          [variables]="getVariables(i)"></app-calculation-panel>
      </div>
      <button (click)="addCalculation()" class="add-rule">
        <span class="material-symbols-rounded">add</span>
        {{ 'project-module.measure-template.add-formula' | translate }}
      </button>
    </div>

    <button type="submit" class="button-primary button--has-icon" [disabled]="isSaving">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'project-module.measure.save' | translate }}
    </button>
  </form>
</div>

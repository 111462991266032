import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormulaService } from '../../services/formula.service';

@Component({
  selector: 'app-calculation-panel',
  templateUrl: './calculation-panel.component.html',
  styleUrls: ['./calculation-panel.component.scss'],
  standalone: false,
})
export class CalculationPanelComponent implements OnChanges {
  @Input() formula: string = '';
  @Input() variables: Record<string, number> = {};

  min: number = 0;
  max: number = 0;
  error: string = '';

  constructor(private formulaService: FormulaService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('formula' in changes || 'variables' in changes) {
      this.calculateFormula();
    }
  }

  private calculateFormula(): void {
    if (!this.formula) {
      this.error = 'project-module.calculation-panel.no-formula';
      return;
    }

    if (!this.variables) {
      this.error = 'project-module.calculation-panel.no-variables';
      return;
    }

    const result = this.formulaService.evaluateFormula(this.formula, this.variables);

    this.min = result.min;
    this.max = result.max;
    this.error = result.error || '';
  }
}

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FloorPlanComponent } from '@shared/components/floor-plan/floor-plan.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, distinctUntilChanged, map, ReplaySubject, skip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { isBimRoom } from '../model-viewer/utils/types';
import { BimPropertyService } from '../../services';
import { BimService, BuildingSortColumn, SortOrder } from '@api-clients/bim';
import { ReferenceType } from '@api-clients/bim/model/referenceType';

@Component({
  selector: 'app-floor-plan-viewer',
  templateUrl: './floor-plan-viewer.component.html',
  // Using model-viewer.component.scss because the styling is too similar.
  styleUrl: '../model-viewer/model-viewer.component.scss',
  standalone: false,
})
export class FloorPlanViewerComponent implements OnInit {
  @ViewChild(FloorPlanComponent, { static: true }) floorPlan!: FloorPlanComponent;
  @ViewChild('topBar') private topBar: ElementRef<HTMLElement> | undefined;
  protected noLinkedBimAvailable: boolean = false;

  protected bimId = new ReplaySubject<string>(1);
  protected selectedElementId = new BehaviorSubject<string | undefined>(undefined);

  protected availableHeight: number = 0;
  public shoppingCartCount: number = 0;
  protected bounceShoppingCartButton: boolean = false;
  protected visibleMenu: 'overview' | 'changes' | 'details' = 'overview';
  protected isSaving: boolean = false;
  protected breadcrumbTree: { name: string; link: string }[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private readonly bimPropertyService: BimPropertyService,
    private readonly bimService: BimService
  ) {
    const bim_id = activatedRoute.parent?.snapshot.paramMap.get('bim_id');
    const real_estate_building_id =
      activatedRoute.parent?.snapshot.paramMap.get('real_estate_building_id');

    if (bim_id) {
      this.bimId.next(bim_id);
      bimPropertyService.setBimId(bim_id);
      this.updateBreadCrumb();
    } else if (real_estate_building_id) {
      this.bimService
        .bimGet(
          0,
          1,
          BuildingSortColumn.Description,
          SortOrder.Asc,
          undefined,
          undefined,
          undefined,
          {
            optional_BimSubject_id: real_estate_building_id,
            optional_BimSubject_type: ReferenceType.RealEstateBuilding,
          }
        )
        .subscribe((bim) => {
          if (bim.items.length === 0) {
            this.noLinkedBimAvailable = true;
            return;
          }
          const bim_id = bim.items[0].id;
          this.bimId.next(bim_id);
          this.updateBreadCrumb();
        });
    }

    // Keep track of the current menu state
    this.activatedRoute.paramMap
      .pipe(
        takeUntilDestroyed(),
        map((paramMap) => paramMap.get('state')),
        distinctUntilChanged()
      )
      .subscribe((state: string | null) => {
        if (state === 'changes') {
          if (this.shoppingCartCount > 0) this.visibleMenu = 'changes';
          else this.navigate('overview');
        } else if (state === 'details') {
          this.visibleMenu = 'details';
        } else if (state === 'overview') {
          this.visibleMenu = 'overview';
          this.bimPropertyService.selectBimElement(undefined);
        }
        this.updateBreadCrumb();
      });

    this.bimPropertyService.selectedElement
      .pipe(takeUntilDestroyed())
      .subscribe((selectedElement) => {
        this.selectedElementId.next(selectedElement?.id);
        if (selectedElement) {
          this.navigate('details');
        } else {
          this.navigate('overview');
        }
      });
    // Todo: this button logic should be moved to a separate component
    this.bimPropertyService.changeCount.pipe(skip(1)).subscribe((count) => {
      if (count > 0) {
        this.bounceShoppingCartButton = true;
        setTimeout(() => {
          this.bounceShoppingCartButton = false;
        }, 400);
      }
    });

    this.bimPropertyService.changeCount.subscribe((count) => {
      this.shoppingCartCount = count;
      if (this.shoppingCartCount == 0 && this.visibleMenu === 'changes') {
        this.navigate('overview');
      }
    });

    this.bimPropertyService.unsavedElements
      .pipe(takeUntilDestroyed())
      .subscribe((unsavedElements) => {
        // If a room changed category, update the floor plan
        for (const unsavedElement of unsavedElements) {
          // Todo: only recategorize if the category changed
          if (!isBimRoom(unsavedElement.element)) continue;
          this.floorPlan.recategorizeRoom(unsavedElement.id, unsavedElement.element.category);
        }

        this.selectedElementId.next(undefined);
      });
  }

  async ngOnInit(): Promise<void> {
    this.updateBreadCrumb();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateAvailableHeight();
  }

  calculateAvailableHeight(extraHeight = 80): void {
    const topBar = this.topBar ? this.topBar.nativeElement.clientHeight : 0;
    this.availableHeight = window.innerHeight - topBar - extraHeight;
  }

  protected selectBimId(id: string | undefined): void {
    this.bimPropertyService.selectBimElement(id);
  }

  updateBreadCrumb(): void {
    const root = this.activatedRoute.snapshot.parent?.url.join('/');
    this.breadcrumbTree = [];
    this.breadcrumbTree.push({
      name: this.translateService.instant('building_information'),
      link: '/' + root + '/floor-plan/overview',
    });
    if (this.visibleMenu === 'changes' || this.visibleMenu === 'details') {
      this.breadcrumbTree.push({
        name: this.translateService.instant(this.visibleMenu),
        link: '/' + root + '/floor-plan/' + this.visibleMenu,
      });
    }

    this.calculateAvailableHeight();
  }

  showShoppingCart(): void {
    this.navigate('changes');
  }

  async saveShoppingCart(): Promise<void> {
    if (this.isSaving) return;
    this.isSaving = true;
    await this.bimPropertyService.submitChanges();
    this.toastr.success(this.translateService.instant('changes_saved'));
    this.isSaving = false;
    this.navigate('details');
  }

  downloadSvg(): void {
    this.floorPlan.resetViewPort();
    window.setTimeout(() => {
      const svg = this.floorPlan.svg.nativeElement.cloneNode(true) as SVGElement;
      const att = document.createAttribute('viewBox');
      att.value = this.floorPlan.viewBox;
      svg.attributes.setNamedItem(att);

      const width_att = document.createAttribute('width');
      width_att.value = this.floorPlan.width.toString();
      svg.attributes.setNamedItem(width_att);
      const height_att = document.createAttribute('height');
      height_att.value = this.floorPlan.height.toString();
      svg.attributes.setNamedItem(height_att);

      // reset panzoom transform
      const x_translate = 0 - this.floorPlan.floorPlan.boundingBox.x1;
      const y_translate = 0 - this.floorPlan.floorPlan.boundingBox.y1;
      const translate = `translate(${x_translate},${y_translate})`;
      const panzoomelement = svg.getElementsByClassName('svg-pan-zoom_viewport')[0];
      panzoomelement.setAttribute('transform', translate);

      this.cleanAngularAttributes(svg);
      const svg_content = svg.outerHTML;
      const blob = new Blob([svg_content], {
        type: 'image/svg+xml',
      });

      const downloadelem = document.createElement('a');
      const url = URL.createObjectURL(blob);
      downloadelem.download = `floorplan - ${
        this.floorPlan.floorPlan.levels[this.floorPlan.currentLevel].description
      }.svg`;
      downloadelem.href = url;
      downloadelem.click();
      downloadelem.remove();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  private cleanAngularAttributes(element: SVGElement): void {
    element.querySelectorAll('*').forEach((el) => {
      Array.from(el.attributes).forEach((attr) => {
        if (attr.name.startsWith('_ng') || attr.name.startsWith('ng-')) {
          el.removeAttribute(attr.name);
        }
      });
    });

    // Remove all comments
    const walker = document.createTreeWalker(element, NodeFilter.SHOW_COMMENT, null);
    const commentsToRemove: Comment[] = [];

    while (walker.nextNode()) {
      commentsToRemove.push(walker.currentNode as Comment);
    }

    commentsToRemove.forEach((comment) => comment.parentNode?.removeChild(comment));
  }

  navigate(menu: 'overview' | 'changes' | 'details'): void {
    void this.router.navigate(['../' + menu], { relativeTo: this.activatedRoute });
  }
}

import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';
import { Mode } from '../../../auth/authorized-view/mode';
import { UserService } from '@services/user.service';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';
import { PermissionService } from '../../../auth/permission-service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: false,
})
export class MainMenuComponent implements OnInit {
  userId: string = '';
  userOrganizationId: string = '';

  constructor(
    public oidcSecurityService: OidcSecurityService,
    public userService: UserService,
    protected permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.userId = this.userService.userId;
    this.userOrganizationId = this.userService.organizationId;
  }

  logoff(): Promise<unknown> {
    return lastValueFrom(this.oidcSecurityService.logoff());
  }

  protected readonly Mode = Mode;
  protected readonly alert = alert;
  protected readonly Resource = Resource;
  protected readonly Scope = Scope;
}

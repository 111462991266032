import { Component, OnInit, Type } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProjectStatus } from '@api-clients/project';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';

interface Tile<T = unknown> {
  id: string;
  title: string;
  content: Type<T>;
  width?: string;
}

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  standalone: false,
})
export class ProjectDashboardComponent implements OnInit {
  //Color picker is temp for demo showcase
  tiles: Tile[] = [
    { id: 'test', title: 'Test', content: ColorPickerComponent, width: 'full' },
    { id: 'wow', title: 'Wow', content: ColorPickerComponent },
    { id: 'titeltje', title: 'Titeltje', content: ColorPickerComponent },
    { id: 'berichten', title: 'Berichten', content: ColorPickerComponent },
  ];

  protected breadcrumbTree = [
    {
      translate: 'project-module.measure.overview-page',
      link: '/measure',
    },
    { translate: 'project-module.measure.page', link: '' },
  ];

  ngOnInit(): void {
    const savedTilesOrder = localStorage.getItem('project-dashboard-tiles-order');
    if (savedTilesOrder) {
      const storedOrder = JSON.parse(savedTilesOrder) as string[];
      this.tiles = this.restoreTileOrder(storedOrder, this.tiles);
    }
  }

  drop(event: CdkDragDrop<Tile[]>): void {
    moveItemInArray(this.tiles, event.previousIndex, event.currentIndex);
    const tileOrder = this.tiles.map((tile) => tile.id);
    localStorage.setItem('project-dashboard-tiles-order', JSON.stringify(tileOrder));
  }

  private restoreTileOrder(savedOrder: string[], defaultTiles: Tile[]): Tile[] {
    const tileMap = new Map(defaultTiles.map((tile) => [tile.id, tile]));
    const sortedTiles: Tile[] = savedOrder
      .map((id) => tileMap.get(id))
      .filter((tile): tile is Tile => tile !== undefined);

    const newTiles = defaultTiles.filter((tile) => !savedOrder.includes(tile.id));
    return [...sortedTiles, ...newTiles];
  }

  protected readonly ProjectStatus = ProjectStatus;
  protected readonly Object = Object;
}

import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SelectItemComponent } from './select-item/select-item.component';

@Component({
    selector: 'app-big-select',
    templateUrl: './big-select.component.html',
    styleUrls: ['./big-select.component.scss'],
    animations: [
        trigger('fadeSlideInOut', [
            transition(':leave', [animate('50ms', style({ opacity: 0, transform: 'translateY(10px)' }))]),
        ]),
    ],
    standalone: false
})
export class BigSelectComponent implements AfterContentInit {
  @Input() label = '';
  @Input() current = '';
  @Input() icon = '';
  @Input() iconStyle = 'material-symbols-rounded';
  @Input() active = false;

  @ContentChildren(SelectItemComponent)
  templates!: QueryList<SelectItemComponent>;

  /*
   * We Subscribe to the child component's event to be able to close the dropdown
   * */
  ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      template.response.subscribe(() => {
        this.handleEmit();
      });
    });
  }

  handleEmit(): void {
    this.close();
  }

  close(): void {
    this.active = false;
  }

  toggle(): void {
    this.active = !this.active;
  }
}

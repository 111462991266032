import { Component, Input, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
    standalone: false
})
export class TableFilterComponent {
  @Input() filters = [];

  constructor(private renderer: Renderer2) {}

  removeFilter(event): void {
    this.renderer.addClass(event.target, 'remove');
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InspectionOverviewComponent } from '../overview-page/inspection-overview.component';

@Component({
    selector: 'app-overview-wrapper',
    templateUrl: './overview-wrapper.component.html',
    styleUrl: './overview-wrapper.component.scss',
    standalone: false
})
export class OverviewWrapperComponent {
  protected inspectionsOverviewActive: boolean = false;

  constructor(private readonly router: Router) {}

  toggleViewMode(): void {
    if (this.inspectionsOverviewActive) {
      void this.router.navigate(['inspections', 'templates']);
    } else {
      void this.router.navigate(['inspections']);
    }
  }

  onRouterOutletActivate(e: unknown): void {
    this.inspectionsOverviewActive = e instanceof InspectionOverviewComponent;
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: false
})
export class BreadcrumbComponent {
  @Input() tree: {
    name?: string;
    translate?: string;
    link: string;
    queryParams?: Record<string, string>;
  }[] = [];

  constructor(private router: Router) {}

  changeRouteSection(link: string, queryParams?: Record<string, string>): void {
    void this.router.navigate([link], { queryParams });
  }
}

<div class="main-menu">
  <a class="logo" href="/">
    <img alt="TwinTopics" src="/assets/logo.png" />
  </a>

  <ul *ngIf="oidcSecurityService.isAuthenticated$ | async as authInfo">
    <li>
      <a [routerLinkActive]="['active']" [routerLink]="['/buildings']">
        <span class="material-symbols-rounded">deployed_code</span>
        <span class="link">{{ 'buildings' | translate }}</span>
      </a>
    </li>
    <app-authorized-view [permission]="Resource.Projects" [scope]="Scope.READ">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/project']">
          <span class="material-symbols-rounded">engineering</span>
          <span class="link">{{ 'projects' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>
    <app-authorized-view [permission]="Resource.OwnOrganization" [scope]="Scope.READ">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/bim']">
          <span class="material-symbols-rounded">frame_inspect</span>
          <span class="link">{{ 'bim_overview' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>
    <li>
      <a
        [routerLinkActive]="['active']"
        [routerLink]="['/users/', userId]"
        [routerLinkActiveOptions]="{ exact: true }">
        <span class="material-symbols-rounded">person</span>
        <span class="link">{{ 'profile' | translate }}</span>
      </a>
    </li>
    <app-authorized-view
      [permission]="[Resource.AllUsers, Resource.OwnOrganizationUsers]"
      [scope]="Scope.READ">
      <li>
        <a
          [routerLinkActive]="['active']"
          [routerLink]="['/users']"
          [routerLinkActiveOptions]="{ exact: true }">
          <span class="material-symbols-rounded">groups</span>
          <span class="link">{{ 'users' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>
    <app-authorized-view [permission]="Resource.Inspections" [scope]="Scope.READ">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/inspections']">
          <span class="material-symbols-rounded">article</span>
          <span class="link">{{ 'inspections' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>

    <app-authorized-view [permission]="Resource.Projects" [scope]="Scope.READ">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/measure']">
          <span class="material-symbols-rounded">bia</span>
          <span class="link">{{ 'measures' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>

    <li
      *ngIf="
        permissionService.userHasPermissionWithScope(Resource.OwnOrganization, Scope.READ) &&
        !permissionService.userHasPermissionWithScope(Resource.AllOrganizations, Scope.READ)
      ">
      <a [routerLinkActive]="['active']" [routerLink]="['/organizations/' + userOrganizationId]">
        <span class="material-symbols-rounded">apartment</span>
        <span class="link">{{ 'organization' | translate }}</span>
      </a>
    </li>

    <app-authorized-view [permission]="Resource.AllOrganizations" [scope]="Scope.READ">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/organizations']">
          <span class="material-symbols-rounded">apartment</span>
          <span class="link">{{ 'organizations' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>

    <li>
      <button (click)="logoff()">
        <span class="material-symbols-rounded">logout</span>
        <span class="link">{{ 'log-off' | translate }}</span>
      </button>
    </li>
  </ul>
</div>

/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateUpdateMeasureTemplateRequest } from '../model/createUpdateMeasureTemplateRequest';
// @ts-ignore
import { MeasureTemplateDto } from '../model/measureTemplateDto';
// @ts-ignore
import { MeasureTemplateListResponse } from '../model/measureTemplateListResponse';
// @ts-ignore
import { MeasureTemplateSortColumn } from '../model/measureTemplateSortColumn';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MeasureTemplatesService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get a paginated list of measure templates
     * Retrieves a list of measure templates for the authenticated user\&#39;s organization. Supports filtering, sorting, and pagination. Requires authentication and &#x60;READ&#x60; permission on the &#x60;MeasureTemplates&#x60; resource.  # Query Parameters - &#x60;filter_text&#x60; (String, optional): A search term to filter measure templates by name or description. - &#x60;page_index&#x60; (i64): The index of the page to retrieve (zero-based). - &#x60;page_size&#x60; (i64): The number of items per page. - &#x60;sort_column&#x60; (MeasureTemplateSortColumn): The column to sort by. - &#x60;sort_order&#x60; (SortOrder): The sorting order (ascending or descending).  # Security - Requires &#x60;READ&#x60; permission on the &#x60;MeasureTemplates&#x60; resource. - User must belong to the organization.  # Responses - **200 OK**: Returns a paginated list of measure templates. - **400 Bad Request**: If query parameters are invalid. - **403 Forbidden**: If the user lacks the required permissions. - **500 Internal Server Error**: If an internal error occurs.
     * @param pageIndex 
     * @param pageSize 
     * @param sortColumn 
     * @param sortOrder 
     * @param filterText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesGet(pageIndex: number, pageSize: number, sortColumn: MeasureTemplateSortColumn, sortOrder: SortOrder, filterText?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureTemplateListResponse>;
    public templatesGet(pageIndex: number, pageSize: number, sortColumn: MeasureTemplateSortColumn, sortOrder: SortOrder, filterText?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureTemplateListResponse>>;
    public templatesGet(pageIndex: number, pageSize: number, sortColumn: MeasureTemplateSortColumn, sortOrder: SortOrder, filterText?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureTemplateListResponse>>;
    public templatesGet(pageIndex: number, pageSize: number, sortColumn: MeasureTemplateSortColumn, sortOrder: SortOrder, filterText?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (pageIndex === null || pageIndex === undefined) {
            throw new Error('Required parameter pageIndex was null or undefined when calling templatesGet.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling templatesGet.');
        }
        if (sortColumn === null || sortColumn === undefined) {
            throw new Error('Required parameter sortColumn was null or undefined when calling templatesGet.');
        }
        if (sortOrder === null || sortOrder === undefined) {
            throw new Error('Required parameter sortOrder was null or undefined when calling templatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filterText !== undefined && filterText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterText, 'filter_text');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'page_index');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortColumn, 'sort_column');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sort_order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measure-templates`;
        return this.httpClient.request<MeasureTemplateListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a measure template
     * Deletes a measure template identified by &#x60;measure_template_id&#x60;. Requires authentication and &#x60;DELETE&#x60; permission on the &#x60;MeasureTemplates&#x60; resource.  # Path Parameters - &#x60;measure_template_id&#x60; (UUID): The unique identifier of the measure template to delete.  # Security - Requires &#x60;DELETE&#x60; permission on the &#x60;MeasureTemplates&#x60; resource. - User must belong to the organization.  # Responses - **204 No Content**: Measure template successfully deleted. - **403 Forbidden**: If the user lacks the required permissions. - **404 Not Found**: If the measure template does not exist. - **500 Internal Server Error**: If an internal error occurs.
     * @param measureTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesMeasureTemplateIdDelete(measureTemplateId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public templatesMeasureTemplateIdDelete(measureTemplateId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public templatesMeasureTemplateIdDelete(measureTemplateId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public templatesMeasureTemplateIdDelete(measureTemplateId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (measureTemplateId === null || measureTemplateId === undefined) {
            throw new Error('Required parameter measureTemplateId was null or undefined when calling templatesMeasureTemplateIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measure-templates/${this.configuration.encodeParam({name: "measureTemplateId", value: measureTemplateId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a measure template by ID
     * Retrieves a specific measure template using its unique identifier. Requires authentication and appropriate permissions.  # Path Parameters - &#x60;measure_template_id&#x60; (UUID): The unique identifier of the measure template.  # Security - Requires &#x60;READ&#x60; permission on the &#x60;MeasureTemplates&#x60; resource.  # Responses - **200 OK**: Returns the measure template details. - **404 Not Found**: If the measure template does not exist. - **500 Internal Server Error**: If an internal error occurs.
     * @param measureTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesMeasureTemplateIdGet(measureTemplateId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureTemplateDto>;
    public templatesMeasureTemplateIdGet(measureTemplateId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureTemplateDto>>;
    public templatesMeasureTemplateIdGet(measureTemplateId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureTemplateDto>>;
    public templatesMeasureTemplateIdGet(measureTemplateId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (measureTemplateId === null || measureTemplateId === undefined) {
            throw new Error('Required parameter measureTemplateId was null or undefined when calling templatesMeasureTemplateIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measure-templates/${this.configuration.encodeParam({name: "measureTemplateId", value: measureTemplateId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<MeasureTemplateDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing measure template
     * Updates the details of a measure template identified by &#x60;measure_template_id&#x60;. Requires authentication and &#x60;UPDATE&#x60; permission on the &#x60;Projects&#x60; resource.  # Path Parameters - &#x60;measure_template_id&#x60; (UUID): The unique identifier of the measure template to update.  # Request Body - &#x60;name&#x60; (String): The updated name of the measure template. - &#x60;description&#x60; (String, optional): The updated description of the template. - &#x60;variables&#x60; (Array): A list of updated variables. - &#x60;calculations&#x60; (Array): A list of updated calculations.  # Security - Requires &#x60;UPDATE&#x60; permission on the &#x60;Projects&#x60; resource.  # Responses - **200 OK**: Measure template successfully updated. - **400 Bad Request**: If the request body is invalid. - **403 Forbidden**: If the user lacks the required permission. - **404 Not Found**: If the measure template does not exist. - **500 Internal Server Error**: If an internal error occurs.
     * @param measureTemplateId 
     * @param createUpdateMeasureTemplateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesMeasureTemplateIdPut(measureTemplateId: string, createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureTemplateDto>;
    public templatesMeasureTemplateIdPut(measureTemplateId: string, createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureTemplateDto>>;
    public templatesMeasureTemplateIdPut(measureTemplateId: string, createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureTemplateDto>>;
    public templatesMeasureTemplateIdPut(measureTemplateId: string, createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (measureTemplateId === null || measureTemplateId === undefined) {
            throw new Error('Required parameter measureTemplateId was null or undefined when calling templatesMeasureTemplateIdPut.');
        }
        if (createUpdateMeasureTemplateRequest === null || createUpdateMeasureTemplateRequest === undefined) {
            throw new Error('Required parameter createUpdateMeasureTemplateRequest was null or undefined when calling templatesMeasureTemplateIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measure-templates/${this.configuration.encodeParam({name: "measureTemplateId", value: measureTemplateId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<MeasureTemplateDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateMeasureTemplateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new measure template
     * Creates a new measure template within the user\&#39;s organization. Requires authentication and &#x60;CREATE&#x60; permission on the &#x60;Projects&#x60; resource.  # Request Body - &#x60;name&#x60; (String): The name of the measure template. - &#x60;description&#x60; (String, optional): A description of the measure template. - &#x60;variables&#x60; (Array): A list of variables associated with the template. - &#x60;calculations&#x60; (Array): A list of calculations to apply.  # Security - Requires &#x60;CREATE&#x60; permission on the &#x60;Projects&#x60; resource.  # Responses - **201 Created**: Measure template successfully created. - **400 Bad Request**: If the request body is invalid. - **403 Forbidden**: If the user lacks the required permission. - **500 Internal Server Error**: If an internal error occurs.
     * @param createUpdateMeasureTemplateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesPost(createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<MeasureTemplateDto>;
    public templatesPost(createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<MeasureTemplateDto>>;
    public templatesPost(createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<MeasureTemplateDto>>;
    public templatesPost(createUpdateMeasureTemplateRequest: CreateUpdateMeasureTemplateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (createUpdateMeasureTemplateRequest === null || createUpdateMeasureTemplateRequest === undefined) {
            throw new Error('Required parameter createUpdateMeasureTemplateRequest was null or undefined when calling templatesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/measure-templates`;
        return this.httpClient.request<MeasureTemplateDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateMeasureTemplateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

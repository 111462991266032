import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Feature } from './floorplan/features';
import { FloorPlan } from './floorplan/floorplan';
import { ModelLoaderService } from '@shared/services/model-loader.service';
// todo seperate the svg component from shopping cart logic
// svg component should only contain svg logic
import SvgPanZoom from 'svg-pan-zoom';
import { RoomCategory } from '@api-clients/bim';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BuildingModel } from '@shared/services/assets/building-model';

@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrl: './floor-plan.component.scss',
  standalone: false,
})
export class FloorPlanComponent implements OnInit {
  @ViewChild('svg', { static: false }) svg!: ElementRef<SVGSVGElement>;
  public currentLevel = -1;
  public floorPlan!: FloorPlan;
  public viewBox: string = '0 0 0 0';
  private scheme?: SvgPanZoom.Instance;
  public hasError: boolean = false;
  public isRotated: boolean = false;
  private readonly zoomFactor = 0.98;

  @Input({ required: true }) public bimId!: Observable<string>;
  @Input({ required: true }) public selectedElementId!: Observable<string | undefined>;
  @Output() public bimIdClicked = new EventEmitter<string | undefined>();

  protected selectedElement?: Feature;
  protected clickDisabled = false;

  constructor(
    private readonly modelLoader: ModelLoaderService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.bimId.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (bimId) => {
      await this.loadModel(bimId);
    });
    this.selectedElementId
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((id) => this.selectId(id));
  }

  private async loadModel(bimId: string): Promise<void> {
    this.hasError = false;
    let buildingModel: BuildingModel | undefined;
    try {
      buildingModel = await this.modelLoader.loadCompositeModelWithMetadata(bimId);
    } catch (error) {
      this.hasError = true;
    }
    if (!buildingModel) {
      this.hasError = true;
      throw new Error('Failed to load model.');
    }

    const floorPlanObservable = await FloorPlan.fromBuildingModel(buildingModel);
    floorPlanObservable.subscribe((next) => {
      this.floorPlan = next;
      if (this.floorPlan.levels.length === 0) return;

      if (next.levels.length === 1) {
        this.currentLevel = 0;
        this.setViewPort();
      }

      this.isRotated = false;
    });
  }

  protected featureClicked(feature: Feature | undefined): void {
    if (this.clickDisabled) return;
    if (feature) this.bimIdClicked.emit(feature.bimId);
    else this.bimIdClicked.emit(undefined);
  }

  private selectId(id: string | undefined): void {
    if (!id) {
      this.selectedElement = undefined;
      return;
    }
    const featureAndLevel = this.floorPlan.findFeatureAndLevelById(id);
    if (featureAndLevel) {
      this.currentLevel = featureAndLevel.level;
      this.selectedElement = featureAndLevel.feature;
    }
  }

  public recategorizeRoom(id: string, category: RoomCategory): void {
    const roomFeature = this.floorPlan.findRoomById(id);
    if (roomFeature) {
      roomFeature.category = category;
    }
  }

  public resetViewPort(): void {
    const zoom = this.isRotated ? (this.height / this.width) * this.zoomFactor : this.zoomFactor;
    this.scheme?.zoom(zoom);
    this.scheme?.resetPan();
    this.scheme?.center();
  }

  public zoomIn(): void {
    this.scheme?.zoomIn();
  }

  public zoomOut(): void {
    this.scheme?.zoomOut();
  }

  public rotate(): void {
    this.isRotated = !this.isRotated;
  }

  private setViewPort(): void {
    if (this.scheme) {
      this.scheme.destroy();
      delete this.scheme;
    }

    this.viewBox = `${this.floorPlan.boundingBox.x1} ${this.floorPlan.boundingBox.y1} ${this.width} ${this.height}`;

    window.setTimeout(() => {
      this.scheme = SvgPanZoom(this.svg.nativeElement, {
        onUpdatedCTM: () => {
          this.clickDisabled = true;
        },
        center: true,
      });
      if (this.scheme) {
        this.scheme.zoom(this.zoomFactor);
      }
    }, 100);
  }

  get height(): number {
    return (
      this.floorPlan.boundingBox.y2 -
      this.floorPlan.boundingBox.y1 +
      this.floorPlan.levels[this.currentLevel].dimensions.widths.length * 200 +
      100
    );
  }

  get width(): number {
    return (
      this.floorPlan.boundingBox.x2 -
      this.floorPlan.boundingBox.x1 +
      this.floorPlan.levels[this.currentLevel].dimensions.heights.length * 200 +
      100
    );
  }

  protected endRotate(): void {
    const zoom = this.isRotated ? (this.height / this.width) * this.zoomFactor : this.zoomFactor;
    this.scheme?.zoom(zoom);
    this.scheme?.center();
  }
}

import { Component } from '@angular/core';
import {
  MeasureSortColumn,
  MeasuresService,
  MeasureTemplateListItem,
  MeasureTemplateSortColumn,
  ProjectSortColumn,
  SortOrder,
} from '@api-clients/project';
import { Router } from '@angular/router';
import { getEnumValue } from '../../../building-module/utils';
import { Scope } from '../../../scopes';
import { Resource } from '../../../resources';
import { MeasurePopupService } from '../../components/measure-copy-popup/measure-copy-popup.service';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-measure-overview',
  templateUrl: './measure-overview.component.html',
  styleUrl: './measure-overview.component.scss',
  standalone: false,
})
export class MeasureOverviewComponent {
  protected defaultPageSize: number = 10;
  protected sortBy: MeasureTemplateSortColumn = MeasureTemplateSortColumn.Name;
  protected sortOrder: SortOrder = SortOrder.Asc;
  protected searchText: string = '';
  protected filteredData: MeasureTemplateListItem[] = [];
  protected totalCount: number = 0;
  protected page: number = 1;
  protected pageSize: number = this.defaultPageSize;
  private selectedMeasureId?: string;
  protected breadcrumbTree = [
    {
      translate: 'project-module.measure.overview-page',
      link: '/measure',
    },
  ];

  // column data is added in html through a loop.
  protected columns: { key: MeasureSortColumn | undefined; label: string }[] = [
    { key: MeasureSortColumn.Name, label: 'name' },
    { key: MeasureSortColumn.Description, label: 'description' },
    { key: MeasureSortColumn.CreationTimestampUtc, label: 'creation-timestamp' },
    { key: MeasureSortColumn.LastModifiedTimestampUtc, label: 'last-modified-timestamp' },
    { key: undefined, label: 'remove' },
  ];

  protected readonly SortOrder = SortOrder;

  constructor(
    private readonly router: Router,
    private readonly measureService: MeasuresService,
    private readonly measurePopupService: MeasurePopupService,
    private readonly confirmationDialogService: ConfirmationDialogService
  ) {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.page = 1;
    this.pageSize = this.defaultPageSize;

    const sort_by = getEnumValue(
      ProjectSortColumn,
      localStorage.getItem('measure-overview-sort-by')
    );
    this.sortBy = sort_by ?? MeasureTemplateSortColumn.Name;
    const sort_order = getEnumValue(SortOrder, localStorage.getItem('measure-overview-sort-order'));
    this.sortOrder = sort_order || SortOrder.Asc;

    this.load();
  }

  load(): void {
    this.measureService
      .measuresGet(
        this.page - 1,
        this.pageSize,
        this.sortBy,
        this.sortOrder,
        undefined,
        undefined,
        this.searchText !== '' ? this.searchText : undefined
      )
      .subscribe((data) => {
        this.filteredData = data.items;
        this.totalCount = data.count;
      });
  }

  onPageChange(event: { page: number; pageSize: number }): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.load();
  }

  toggleSort(property: MeasureTemplateSortColumn | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? MeasureTemplateSortColumn.Name;
      this.sortOrder = SortOrder.Asc;
    }
    localStorage.setItem('measure-overview-sort-by', this.sortBy);
    localStorage.setItem('measure-overview-sort-order', this.sortOrder);
    this.load();
  }

  openDetail(item: MeasureTemplateListItem): void {
    void this.router.navigate(['measure', item.id]);
  }

  search(): void {
    this.load();
  }

  showMeasureTemplatePopup(): void {
    this.measurePopupService.show();
  }

  measureToRemovePopup(event: MouseEvent, measureId?: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (!measureId) return;
    this.selectedMeasureId = measureId;
    this.confirmationDialogService.open();
  }

  removeMeasure(): void {
    if (!this.selectedMeasureId) return;
    this.measureService.measuresIdDelete(this.selectedMeasureId).subscribe(() => {
      this.load();
    });
  }

  goToMeasureTemplates(): void {
    void this.router.navigate(['measure-template']);
  }

  protected readonly Scope = Scope;
  protected readonly Resource = Resource;
}
